  import {
    Document,
    Page,
    Text,
    View,
    Image,
    StyleSheet,
  } from "@react-pdf/renderer";
  import { useAuth } from "../context/authContext";
  import useCountrySelect from "../hooks/useCountrySelect";

  // ✅ Define styles
  const styles = StyleSheet.create({
    page: { padding: 20, fontSize: 12, fontFamily: "Open Sans" },
    section: {
      marginBottom: "40px",
      display: "flex",
      justifyContent: "space-between",
    },

    // 🏢 Header & Business Details
    headerContainer: { flexDirection: "row", justifyContent: "space-between" },
    logo: { width: 60, height: 60, borderRadius: 30 },
    businessDetails: { flex: 1 },
    title: {
      fontSize: 22,
      fontWeight: 700,
      textAlign: "right",
      color: "#404e67",
      // letterSpacing: 0.5,
    },
    stitle: {
      fontSize: 16,
      textAlign: "right",
      color: "#404e67",
      // letterSpacing: 0.5,
    },

    // 📋 Invoice Table
    table: { display: "flex", width: "100%", marginTop: 50 },
    row: { flexDirection: "row", borderBottom: "1px solid #ddd", padding: 5 },
    header: { color: "#404e67", fontWeight: 700 },
    cell: { flex: 1, padding: 5, color: "#404e67" },

    // 💰 Outstanding Balance Section
    balanceContainer: {
      // width: "358px",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 10,
      padding: 10,
      backgroundColor: "#EEE",
      gap: 20,
      display: "flex",
    },
    balanceLabel: { fontWeight: "bold" },
  });

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle empty cases
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "short", // "Feb"
      day: "2-digit", // "18"
      year: "numeric", // "2025"
    });
  };

  const StatementPDF = ({
    clientdetails,
    clientinvoiceslist,
    totalOutstanding,
    notYetDue,
    selectedCountry,
    currencySymbol,
    
  }) => {

    const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
      // const { authData, setAuthUser } = useAuth();
      // const user = authData.user;
      // const { countryOptions } = useCountrySelect();
      // const userCountry = user.country; // Assume user.country exists
      // const countryData = countryOptions.find(c => c.value === userCountry);
      // const currencySymbol = countryData ? countryData.currencySymbol :"$";

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          {/* 🏢 Header Section */}
          <View style={styles.headerContainer}>
            {clientdetails.logo && (
              <Image
                src={`${IMAGE_URL}/${clientdetails.logo}`}
                style={{
                  ...styles.logo,
                  width: "175px",
                  height: "175px",
                  objectFit: "cover",
                  borderRadius: 100,
                }}
              />
            )}

            <View
              style={[
                styles.businessDetails,
                {
                  marginLeft: clientdetails.logo ? 10 : 0,
                },
              ]}
            >
              <Text style={{ fontWeight: 700, fontSize: 12, color: "#404e67" }}>
                {clientdetails.business_name}
              </Text>
              <Text>{clientdetails.address}</Text>
              <Text>
                {clientdetails.city ? `${clientdetails.city}, ` : ""}
                {clientdetails.country}
              </Text>
            </View>
            <View>
              <Text style={styles.title}>Statement of Account</Text>
              <View
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                }}
              >
                <Text style={[styles.stitle, { marginTop: 5 }]}>
                  Outstanding invoices
                </Text>
              </View>
            </View>
          </View>

          {/* 🏷️ Bill To Section */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 80,
            }}
          >
            <View>
              <Text
                style={{
                  color: "#404e67",
                  fontSize: 11,
                  // letterSpacing: 0.5,
                  paddingBottom: 2,
                }}
              >
                Bill to{" "}
              </Text>
              <Text
                style={{
                  fontWeight: 700,
                  fontSize: 11,
                  color: "#404e67",
                  // letterSpacing: 0.5,
                }}
              >
                {clientdetails.name}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                textAlign: "right",
              }}
            >
              <Text
                style={{
                  fontWeight: 700,
                  fontSize: 18,
                  color: "#404e67",
                  // letterSpacing: 0.5,
                }}
              >
                {/* Australian Dollar (AUD) */}
                {selectedCountry ? `${selectedCountry.label} ${selectedCountry.label3} (${selectedCountry.label2})` : "Select a country" }

              </Text>

              <View
                style={{
                  padding: 12,
                  fontSize: 12,
                  // letterSpacing: 0.5,
                  color: "#404e67",
                }}
              >
                <Text>As of {formatDate(new Date())}</Text>
              </View>

              <View
                style={{
                  width: "100%",
                  borderBottomWidth: 1,
                  borderColor: "#E6E6E6",
                  marginVertical: 4,
                }}
              />

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: 12,
                  padding: 15,
                  lineHeight: 1.45,
                  letterSpacing: 1,
                  color: "#404e67",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                  }}
                >
                  <Text>Overdue</Text>
                  <Text>Not yet due</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    marginLeft: 16,
                  }}
                >
                  <Text>{currencySymbol}{parseFloat(totalOutstanding).toFixed(2)}</Text>
                  <Text>{currencySymbol}{parseFloat(notYetDue).toFixed(2)}</Text>
                </View>
              </View>

              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: 8,
                  backgroundColor: "rgba(202, 200, 200, 0.5)",
                  fontSize: 12,
                  fontWeight: 700,
                  gap: 20,
                  color: "#404e67",
                  letterSpacing: 1,
                }}
              >
                <Text>Outstanding Balance ({selectedCountry.label2})</Text>
                <Text style={{ fontWeight: "bold" }}>
                {currencySymbol} {parseFloat(totalOutstanding).toFixed(2)}
                </Text>
              </View>
            </View>
          </View>

          {/* 💰 Outstanding Balance */}

          {/* 📋 Invoice Table */}
          <View style={styles.table}>
            <View style={[styles.row, styles.header]}>
              <Text style={styles.cell}>Invoice #</Text>
              <Text style={styles.cell}>Invoice Date</Text>
              <Text style={styles.cell}>Due Date</Text>
              <Text style={styles.cell}>Total</Text>
              <Text style={styles.cell}>Paid</Text>
              <Text style={styles.cell}>Due</Text>
            </View>
            {clientinvoiceslist.length > 0 ? (
              clientinvoiceslist.map((invoice, index) => {
                return (
                  <View key={index} style={styles.row}>
                    <Text
                      style={{
                        ...styles.cell,
                        color: "rgb(21, 91, 255)",
                        fontWeight: 700,
                      }}
                    >
                      Invoice {invoice.id}
                    </Text>
                    <Text style={styles.cell}>
                      {formatDate(invoice.invoice_date)}
                    </Text>
                    <Text style={[styles.cell]}>
                      {invoice.due_date ? formatDate(invoice.due_date) : formatDate(invoice.updated_at)}
                      {"\n"}
                      <Text style={{ color: "red", fontWeight: 700 }}>
                        Overdue
                      </Text>
                    </Text>
                    <Text style={styles.cell}>
                      {currencySymbol}{parseFloat(invoice.total_price).toFixed(2)}
                    </Text>
                    <Text style={styles.cell}>
                    {currencySymbol}{parseFloat(invoice.paid_amount).toFixed(2)}
                    </Text>
                    <Text style={styles.cell}>
                    {currencySymbol}
                      {parseFloat(
                        invoice.total_price - invoice.paid_amount
                      ).toFixed(2)}
                    </Text>
                  </View>
                );
              })
            ) : (
              <View style={styles.row}>
                <Text style={[styles.cell, { flex: 6, textAlign: "center" }]}>
                  No invoices found
                </Text>
              </View>
            )}
          </View>

          {/* 🔹 Final Outstanding Balance */}

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginBottom: 12,
              marginTop: 12,
              paddingHorizontal: 8,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 8,
                backgroundColor: "rgba(202, 200, 200, 0.5)",
                fontSize: 12,
                fontWeight: 700,
                gap: 20,
                color: "#404e67",
                letterSpacing: 1,
              }}
            >
              <Text>Outstanding Balance ({selectedCountry.label2})</Text>
              <Text style={{ fontWeight: "bold" }}>
              {currencySymbol} {parseFloat(totalOutstanding).toFixed(2)}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  export default StatementPDF;
