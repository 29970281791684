import { useState } from "react";
const useCountrySelect = (initialValue = null) => {



  const countryOptions = [
    { value: "USA", label: "USA", label2: "USD", label3: "Dollar", currencySymbol: "$" },
    { value: "UK", label: "UK", label2: "GBP", label3: "Pound", currencySymbol: "£" },
    { value: "Brazil", label: "Brazil", label2: "BRL", label3: "Real", currencySymbol: "R$" },
    { value: "Japan", label: "Japan", label2: "JPY", label3: "Yen", currencySymbol: "¥" },
    { value: "Taiwan", label: "Taiwan", label2: "TWD", label3: " Dollar", currencySymbol: "NT$" },
    { value: "Singapore", label: "Singapore", label2: "SGD", label3: " Dollar", currencySymbol: "S$" },
    { value: "Australia", label: "Australia", label2: "AUD", label3: "Dollar", currencySymbol: "A$" },
    { value: "New Zealand", label: "New Zealand", label2: "NZD", label3: "Dollar", currencySymbol: "NZ$" },
    { value: "India", label: "India", label2: "INR", label3: "Rupee", currencySymbol: "₹" },
    { value: "Thailand", label: "Thailand", label2: "THB", label3: "Baht", currencySymbol: "฿" },
  ];
  
  
  const defaultCountry = countryOptions.find(c => c.value === initialValue || null)
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const [error, setError] = useState("");
  const handleSelectChange = (selectedOption) => {
    // if (!selectedOption) {
    //   setError("Country is required");
    // } else {
    //   setError("");
    // }
    setSelectedCountry(selectedOption);

  };

  return { selectedCountry, handleSelectChange, countryOptions, error };
};

export default useCountrySelect;
