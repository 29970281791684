import React from "react";
import ManageClients from "../components/manageClients";

const AddClients = () => {
  return (
    <div>
      <ManageClients />
    </div>
  );
};

export default AddClients;
