import React, { useState, useEffect } from "react";
import "./EditInvoiceModal.css";
import { getInvoiceData, sendInvoice, updateInvoice } from "../api/invoiceApis";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAuth } from "../context/authContext";
import { getOrderDataForInvoice, saveInvoice } from "../api/collectionApis";
import { getAllServices } from "../api/serviceApis";
import SelectItemModal from "../components/SelectItemModal";
import { Dropdown, Spinner } from "react-bootstrap";
import useCountrySelect from "../hooks/useCountrySelect";
import { Autocomplete, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Select from "react-select";
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const EditInvoiceModal = ({
  isOpen,
  onClose,
  setNewInvoiceId,
  newInvoiceId,
  invoiceId,
  handleLoading,
  isEdit,
  collectionId,
  refreshInvoiceList,
}) => {
  const [invoiceData, setInvoiceData] = useState(null);
  console.log("🚀 ~ invoiceData:", invoiceData);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [taxRate, setTaxRate] = useState(10);
  const [note, setNote] = useState("");
  const [othernotes, setOtherNotes] = useState("");
  const [invoiceLink, setInvoiceLink] = useState("");
  const [paidAmount, setPaidAmount] = useState(0);
  const { authData } = useAuth();
  const user = authData.user;
  const subdomainId = user.subdomain_id;
  const userId = user.id;
  const roleId = user.role_id;
  const accesstoken = authData.token;
  const [clientData, setClientData] = useState({
    emails: [],
    email: "",
    id: null,
    default_email: "",
    name: "",
    business_name: "",
  });
  const [clientAddress, setClientAddress] = useState("");
  const [paidStatus, setPaidStatus] = useState(false);
  const [availableItems, setAvailableItems] = useState([]);
  const [isItemSelectModalOpen, setIsItemSelectModalOpen] = useState(false); // State for item selection modal
  const { countryOptions } = useCountrySelect();
  const selectedCountry = roleId === 5 ? user?.country : user?.adminCountry;
  const countryData = countryOptions.find((c) => c.value === selectedCountry);
  const currencySymbol = countryData ? countryData.currencySymbol : "$";
  const [selectedEmail, setSelectedEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSavInvoice, setIsSaveInvoice] = useState(false);
  useEffect(() => {
    if (isOpen && invoiceId) {
      const fetchInvoiceData = async () => {
        try {
          let dataToSend = new FormData();
          dataToSend.append("invoiceId", invoiceId);
          let response = await getInvoiceData(dataToSend);
          let itemsArray = response.data.itemsArray;
          setItems(itemsArray);
          setInvoiceData(response.data);
          setPaidAmount(response.data.invoice.paid_amount);
          setNote(response.data.invoice.notes);
          setInvoiceLink(response.data.invoice.invoice_link);
          setNewInvoiceId(response?.data?.invoice?.invoice_id);
          setTaxRate(response.data.admin.tax_rate);
          const newAddress = response.data.invoice.user_address;

          // setInvoiceData((prevData) => ({
          //   ...prevData,
          //   client: {
          //     ...prevData.client,
          //     address: newAddress,
          //   },
          // }));
          setClientData(response.data.client);
          setSelectedEmail(
            response.data.invoice.email
              ? response.data.invoice.email
              : response.data.client.default_email
              ? response.data.client.default_email
              : response.data.client.email
          );
          setClientAddress(newAddress);
          setPaidStatus(response.data.invoice.paid_status);
          setOtherNotes(response.data.invoice.other_notes);
          setValue(response.data.invoice.due_duration);
        } catch (err) {
          setError(err.message);
        }
        handleLoading();
      };
      fetchInvoiceData();
      getServiceList();
    } else if (isOpen && collectionId) {
      const fetchInvoiceData = async () => {
        try {
          const data = await getOrderDataForInvoice(collectionId);
          setValue(data.data.due_duration);
          setInvoiceData(data.data);
          setClientAddress(data.data.collection.client_address || "");
          setNewInvoiceId(data?.data?.next_invoice_id);
          let initializedItems = (data.data.packages || []).map((item) => ({
            ...item,
            quantity: item.quantity || 1,
          }));

          console.log("initializedItems :>> ", initializedItems);

          // initializedItems.forEach((item) => {
          //   item.details = JSON.parse(item.details);
          //   item.details = item.details
          //     .map(
          //       (detail) =>
          //         `${detail.image_type_count} ${detail.image_type_label}`
          //     )
          //     .join(", ");
          // });
          // initializedItems = initializedItems.map((item) => {
          //   const details = item.details;
          //   delete item.details;
          //   item.description = details;
          //   return item;
          // });
          setTaxRate(data.data.admin.tax_rate);
          setItems(initializedItems);
          setClientData(data.data.client);

          setSelectedEmail(
            data.data.client.default_email
              ? data.data.client.default_email
              : data.data.client.email
          );
          setNote(data.data.collection.client_address || "");
        } catch (err) {
          setError(err.message);
        }
        handleLoading();
      };

      fetchInvoiceData();
      getServiceList();
    } else {
      setInvoiceData({
        invoice: {
          order_id: "",
          updated_at: new Date(),
        },
        client: {
          name: "",
          address: "",
          email: "",
          phone: "",
          business_name: "",
        },
        admin: {
          name: "",
          logo: "",
        },
      });
      setItems([
        { id: Date.now(), name: "", description: "", quantity: 1, price: 0 },
      ]);
      setPaidAmount(0);
      handleLoading();
    }
  }, [isOpen, invoiceId, isEdit]);
  /*const addItem = () => {
    setItems([
      ...items,
      { id: Date.now(), name: "", description: "", quantity: 1, price: 0 },
    ]);
  };*/

  const addItem = () => {
    setIsItemSelectModalOpen(true); // Open the item selection modal
  };

  const handleSelectItem = (selectedItem) => {
    console.log("selectedItem :>> ", selectedItem);

    setItems([
      ...items,
      {
        id: selectedItem.id,
        name: selectedItem.package_name,
        description: selectedItem?.description || "",
        quantity: 1,
        price: selectedItem.package_price,
      },
    ]);
    setIsItemSelectModalOpen(false); // Close the item selection modal
  };

  const getServiceList = async () => {
    //setItemsLoading(true);
    try {
      const formData = new FormData();
      formData.append("role_id", roleId);
      formData.append("subdomain_id", subdomainId);
      formData.append("user_id", user.id);
      const response = await getAllServices(formData);
      // Filter the items to get only those with status "Active"
      const activeItems = response.data.filter(
        (item) => item.status === "Active"
      );
      // Set the filtered items to the state
      setAvailableItems(activeItems);
    } catch (error) {
      console.error("Error fetching Service list:", error);
    }
    //setItemsLoading(false);
  };

  const deleteItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleItemChange = (index, field, value) => {
    const newItems = items.slice();
    newItems[index] = { ...newItems[index], [field]: value };
    setItems(newItems);
  };

  const calculateSubtotal = () => {
    return items.reduce((sum, item) => sum + item.quantity * item.price, 0);
  };

  const calculateTaxAmount = (subtotal) => {
    return subtotal / (taxRate + 1);
  };

  const calculateTotal = (subtotal, taxAmount) => {
    return subtotal; //+ taxAmount;
  };

  const handleStatusChange = (event) => {
    const value = event.target.value === "Paid" ? true : false;
    setPaidStatus(value);
    if (value === true) {
      setPaidAmount(total);
    }
  };

  const resetData = () => {
    setItems([]);
    setTaxRate(11);
    setNote("");
    setInvoiceLink("");
    setPaidAmount(0);
    setClientAddress("");
    setClientData({
      business_name: "",
      default_email: "",
      email: "",
      emails: [],
      id: null,
      name: "",
    });
    setSelectedEmail("");
  };

  const handleSubmit = async (e, id, send_invoice) => {
    e.preventDefault();
    if (!dueDateOption) {
      toast.error("Please select a due date");
      return;
    }
    if (send_invoice) {
      setLoading(true);
    } else {
      setIsSaveInvoice(true);
    }

    handleLoading();
    const subtotal = calculateSubtotal();
    const taxAmount = calculateTaxAmount(subtotal);
    const total = calculateTotal(subtotal, taxAmount);
    const paidAmt = total;
    const invoice = {
      items,
      subtotal,
      taxRate,
      taxAmount,
      total,
      note,
      invoiceLink,
      clientName: invoiceData.client.name,
      clientAddress: clientAddress,
      dueAmount: total - paidAmt,
      paidAmount: paidAmt,
      paidStatus,
      subdomainId,
      clientId: clientData.id,
      business_name: invoiceData.client.business_name,
      other_notes: othernotes,
      due_date: dueDate,
      due_duration: selected?.value,
      id,
    };

    try {
      let response;
      if (isEdit) {
        invoice.rowId = invoiceId;
        invoice.invoiceId = newInvoiceId;
        invoice.invoice_id = invoiceId;
        invoice.orderId = invoiceData.invoice.order_id;
        response = await updateInvoice(invoice);
        if (response && response.success) {
          toast.success(`Invoice updated successfully!`);
          refreshInvoiceList();
          onClose();
        }
      } else {
        invoice.invoiceId = newInvoiceId;
        invoice.collectionId = collectionId;
        response = await saveInvoice(invoice);
        if (response && response.success) {
          toast.success(`Invoice created successfully!`);

          if (send_invoice && response.invoice.id) {
            await sendInvoice({
              rowId: response.invoice.id,
              currency: currencySymbol,
            });
            toast.success("Invoice sent successfully!");
          }
          refreshInvoiceList();
          resetData();
          onClose();
        } else if (response?.code === 500) {
          toast.error(response?.message);
        } else {
          toast.error(`Failed to ${isEdit ? "update" : "create"} the invoice.`);
        }
      }
      setLoading(false);
      setIsSaveInvoice(false);
    } catch (error) {
      console.log("errorrr", error);
      toast.error(
        `An error occurred while ${
          isEdit ? "updating" : "creating"
        } the invoice.`
      );
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handlePaidAmountChange = (e) => {
    setPaidAmount(parseFloat(e.target.value));
  };

  const subtotal = calculateSubtotal();
  const taxAmount = calculateTaxAmount(subtotal);
  const total = calculateTotal(subtotal, taxAmount);
  const paidAmt = paidStatus ? total : 0;
  const dueAmount = total - paidAmt;

  const days = [
    { label: "Within 15 days", value: 15 },
    { label: "Within 30 days", value: 30 },
    { label: "Within 45 days", value: 45 },
    { label: "Within 60 days", value: 60 },
    { label: "Within 90 days", value: 90 },
  ];

  const [dateIssue, setDateIssue] = useState(new Date()); // Default to today's date
  const [dueDate, setDueDate] = useState(null); // State to store calculated due date
  console.log(dueDate);
  const [dueDateOption, setDueDateOption] = useState(null); // State to store the selected option
  const [selected, setSelected] = useState("");
  const [value, setValue] = useState(null);

  function formatDateWithOffset(isoString, daysToAdd) {
    const date = new Date(isoString); // Convert ISO string to Date object
    date.setDate(date.getDate() + daysToAdd); // Add the given number of days

    // Format the date as dd/MM/yyyy
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const handleDueDateChange = (event, newValue) => {
    setDueDateOption(null);
    setSelected("");
    setDueDate(null);
    if (newValue) {
      const newDate = new Date(dateIssue);
      newDate.setDate(newDate.getDate() + newValue.value);

      setDueDateOption(formatDateWithOffset(dateIssue, newValue.value));
      setDueDate(newDate.toISOString()); // Convert Date to ISO string
      setSelected(newValue);
    } else {
      setDueDateOption(null);
      setDueDate(null);
      setSelected("");
    }
  };

  const getLabelByValue = (value) => {
    const day = days.find((day) => day.value === value);
    return day ? day.label : null; // Return the label or null if no match is found
  };

  useEffect(() => {
    if (value) {
      setDueDateOption(formatDateWithOffset(dateIssue, value));
      setSelected({ value: value, label: getLabelByValue(value) });
      const newDate = new Date(dateIssue);
      newDate.setDate(newDate.getDate() + value);
      setDueDate(newDate.toISOString());
    } else if (value == null) {
      setDueDateOption(null);
      setSelected("");
      setDueDate(null);
    }
  }, [value, isOpen, isEdit, dateIssue]);

  // Effect to handle modal opening and setting the initial invoice date
  useEffect(() => {
    if (invoiceData && invoiceData.invoice) {
      setDateIssue(new Date(invoiceData.invoice.updated_at)); // Set the initial dateIssue
    }
    if (invoiceData && invoiceData?.invoice?.invoiceId) {
      setNewInvoiceId(invoiceData.invoice.invoiceId);
    }
  }, [invoiceData]);

  return (
    <div
      className={`modal fade ${isOpen ? "show" : ""}`}
      style={{ display: isOpen ? "block" : "none" }}
      id="danger"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel10"
      aria-hidden="true"
    >
      {roleId !== 3 ? (
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#DEE6EE" }}
            >
              <h5 className="modal-title">
                {isEdit ? "Edit Invoice" : "Add Invoice"}
              </h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={onClose}
              >
                <i className="feather icon-x" aria-hidden="true" />
              </button>
            </div>
            <div
              className="modal-body"
              style={{ overflowX: "hidden", overflowY: "auto" }}
            >
              <div
                style={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}
              >
                {invoiceData && (
                  <form>
                    <div
                      className="modal-body"
                      style={{ overflowX: "hidden", overflowY: "auto" }}
                    >
                      <div className="card">
                        <div className="card-body">
                          <div className="card-header">
                            <div className="d-flex flex-wrap row align-items-center justify-content-between w-100 gap-3">
                              {/* Invoice Number Field */}
                              <div className="col-md-12 col-xl-3 d-flex align-items-center justify-content-start pl-0">
                                <h6 className="font-weight-bold invoice-text mr-1">
                                  Invoice#
                                </h6>
                                <input
                                  type="number"
                                  name="invoice"
                                  className="form-control w-50"
                                  value={newInvoiceId}
                                  onChange={(e) =>
                                    setNewInvoiceId(e.target.value)
                                  }
                                />
                              </div>

                              <div className="d-flex flex-column flex-wrap align-items-center justify-content-end pt-1 pt-xl-0 px-0">
                                {/* Date Issue Field */}
                                <div className="d-flex align-items-end justify-content-start">
                                  <h6 className="font-weight-bold invoice-text mr-2">
                                    Date Issue
                                  </h6>
                                  <DatePicker
                                    className="form-control custom-datepicker mr-1"
                                    id="dateIssue"
                                    name="dateIssue"
                                    dateFormat="dd/MM/yyyy"
                                    selected={dateIssue}
                                    onChange={(date) => setDateIssue(date)}
                                    readOnly
                                  />
                                </div>

                                <div className="d-flex align-items-center justify-content-start">
                                  {/* Payment Due Dropdown (Visible until selection) */}
                                  <h6 className="font-weight-bold invoice-text mr-2">
                                    Due Date
                                  </h6>
                                  <div className="d-flex flex-column justify-content-center mt-2">
                                    <Autocomplete
                                      // disablePortal
                                      getOptionDisabled={(option) =>
                                        option.value === selected.value
                                      }
                                      options={days}
                                      value={dueDateOption}
                                      dateFormat="dd/MM/yyyy"
                                      onChange={(event, newValue) =>
                                        handleDueDateChange(event, newValue)
                                      } // Correct function call
                                      sx={{ width: 220 }}
                                      renderInput={(params) => (
                                        <TextField
                                          placeholder="Select Due Date"
                                          // variant="filled"
                                          {...params}
                                          label=""
                                          value={
                                            dueDate
                                              ? new Date(
                                                  dueDate
                                                ).toLocaleDateString()
                                              : ""
                                          } // Convert string to Date
                                          sx={{
                                            "& .MuiInputBase-root": {
                                              height: "40px",
                                            },
                                            "& .MuiInputLabel-root": {
                                              fontSize: "14px",
                                            },
                                          }}
                                          InputProps={{
                                            ...params.InputProps,
                                            readOnly: true, // Read-only field
                                          }}
                                        />
                                      )}
                                    />
                                    <p className="text-black-50 invoice-text">
                                      {selected.label}
                                    </p>
                                  </div>
                                </div>
                                {/* <label htmlFor="">{}</label> */}
                              </div>
                            </div>
                          </div>

                          <hr />
                          <div className="row my-2">
                            <div className="col-12 col-sm-6 order-2 order-sm-1">
                              <h4 className="text-primary invoice-title">
                                Invoice
                              </h4>
                              <input
                                type="text"
                                className="form-control"
                                value={invoiceData.admin.name || ""}
                                readOnly={true}
                              />
                            </div>
                            <div className="col-12 col-sm-6 d-flex order-1 order-sm-1 align-items-center justify-content-end">
                              <>
                                {invoiceData.admin.logo ? (
                                  <img
                                    src={`${IMAGE_URL}/${invoiceData.admin.logo}`}
                                    alt="logo"
                                    height="auto"
                                    width={164}
                                  />
                                ) : (
                                  <span>{invoiceData.admin.subdomain}</span>
                                )}
                              </>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-lg-6 col-sm-12 col-xl-6 col-xs-12">
                              <div className="title-text">Invoice To:</div>
                              <div className="row">
                                <div className="col-12 col-xs-12 mb-1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={invoiceData.client.name || ""}
                                    placeholder="Client Name"
                                    readOnly={true}
                                  />
                                </div>

                                <div className="col-12 col-xs-12 mb-1">
                                  <label className="title-text">
                                    Business Name:
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={
                                      invoiceData.client.business_name || ""
                                    }
                                    placeholder="Business Name"
                                    readOnly={true}
                                  />
                                </div>
                                <div className="col-12 col-xs-12 mb-1">
                                  <textarea
                                    className="form-control"
                                    rows={3}
                                    value={clientAddress}
                                    onChange={(e) =>
                                      // setInvoiceData({
                                      //   ...invoiceData,
                                      //   collection: {
                                      //     ...invoiceData.collection,
                                      //     address: e.target.value,
                                      //   },
                                      // })
                                      setClientAddress(e.target.value)
                                    }
                                    placeholder="Address"
                                  />
                                </div>
                                <div className="col-12 col-xs-12 mb-1">
                                  {/* <Dropdown>
                                    <Dropdown.Toggle
                                      variant="none"
                                      id="dropdown-basic"
                                      className="d-flex form-control align-items-center justify-content-between text-left w-100"
                                    >
                                      {selectedEmail ? selectedEmail : "Email"}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="w-100">
                                      {[
                                        clientData.email,
                                        ...clientData.emails,
                                      ].map((email) => (
                                        <Dropdown.Item
                                          onClick={() =>
                                            setSelectedEmail(email)
                                          }
                                        >
                                          {email}
                                        </Dropdown.Item>
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown> */}
                                  <fieldset className="form-group floating-label-form-group">
                                    <Select
                                      className="select2 w-100"
                                      name="email"
                                      value={
                                        invoiceData.client.invoice_emails
                                          ?.length > 0
                                          ? invoiceData.client.invoice_emails.map(
                                              (email) => ({
                                                label: email,
                                                value: email,
                                              })
                                            )
                                          : [
                                              {
                                                label:
                                                  invoiceData.client
                                                    .default_email,
                                                value:
                                                  invoiceData.client
                                                    .default_email,
                                              },
                                            ]
                                      }
                                      isMulti
                                      isDisabled={true}
                                      hideSelectedOptions
                                      isClearable={false} // Prevents clearing the selected options
                                      components={{
                                        MultiValueRemove: () => null, // Hides the cross (×) icon
                                        Option: ({
                                          data,
                                          innerRef,
                                          innerProps,
                                        }) => (
                                          <div
                                            ref={innerRef}
                                            {...innerProps}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              cursor: "pointer",
                                            }}
                                            className="customOptionClass"
                                          >
                                            <span>{data.label}</span>
                                          </div>
                                        ),
                                      }}
                                    />
                                  </fieldset>
                                  {/* <input
                                    type="email"
                                    className="form-control"
                                    value={invoiceData.client.invoice_emails?.length > 0 ? invoiceData.client.invoice_emails.join(", ") : invoiceData.client.default_email}
                                    placeholder="Client Email"
                                    readOnly={true}
                                  /> */}
                                </div>
                                <div className="col-12 col-xs-12 mb-1">
                                  <input
                                    type="tel"
                                    className="form-control"
                                    value={invoiceData.client.phone || ""}
                                    placeholder="Client Phone"
                                    maxLength="10"
                                    readOnly={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-lg-6 col-sm-12 col-xl-6 col-xs-12"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div className="row">
                                <div
                                  className="col-12 col-xs-12 mb-1"
                                  style={{ lineHeight: "2" }}
                                >
                                  <div className="font-weight-bold pt-md-5 title-text">
                                    Invoice From:
                                  </div>
                                  {invoiceData.admin.business_name}
                                  <br />
                                  {invoiceData.admin.address}
                                  <br />
                                  {invoiceData.admin.email}
                                  <br />
                                  <span className="font-weight-bold">
                                    ABN:
                                  </span>{" "}
                                  {invoiceData.admin.abn_acn}
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="invoice-product-details">
                            <div className="repeater-form">
                              <div data-repeater-list="group-a">
                                <div className="table-responsive">
                                  <table className="table table-bordered mt-3">
                                    <thead>
                                      <tr>
                                        <th>Item Name</th>
                                        <th>Description</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th>Total</th>
                                        {/* {roleId !== 3 && <th>Action</th>} */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {items.map((item, index) => (
                                        <tr key={item.id}>
                                          <td>
                                            <input
                                              type="text"
                                              id={`item-name-${index}`}
                                              name={`item-name-${index}`}
                                              className="form-control"
                                              value={item.name}
                                              onChange={(e) =>
                                                handleItemChange(
                                                  index,
                                                  "name",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="text"
                                              id={`item-description-${index}`}
                                              name={`item-description-${index}`}
                                              className="form-control"
                                              value={item.description}
                                              onChange={(e) =>
                                                handleItemChange(
                                                  index,
                                                  "description",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="number"
                                              id={`item-quantity-${index}`}
                                              name={`item-quantity-${index}`}
                                              className="form-control"
                                              value={item.quantity}
                                              onChange={(e) =>
                                                handleItemChange(
                                                  index,
                                                  "quantity",
                                                  parseInt(e.target.value)
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="number"
                                              id={`item-price-${index}`}
                                              name={`item-price-${index}`}
                                              className="form-control"
                                              value={item.price}
                                              onChange={(e) =>
                                                handleItemChange(
                                                  index,
                                                  "price",
                                                  parseFloat(e.target.value)
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="number"
                                              className="form-control"
                                              value={item.quantity * item.price}
                                              readOnly
                                            />
                                          </td>
                                          {roleId !== 3 && (
                                            <td>
                                              <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={() =>
                                                  deleteItem(index)
                                                }
                                                disabled={items.length === 1}
                                                style={{
                                                  backgroundColor: "#f44336",
                                                  color: "white",
                                                }}
                                              >
                                                Delete
                                              </button>
                                            </td>
                                          )}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              {roleId !== 3 && (
                                <div className="form-group">
                                  <button
                                    className="btn btn-primary mt-1"
                                    type="button"
                                    onClick={addItem}
                                  >
                                    <i className="common-size feather icon-plus-circle" />{" "}
                                    Add Item
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <hr />
                          <div className="invoice-total">
                            <div className="row justify-content-between">
                              <div className="col-12 col-lg-6 col-md-6 col-xl-5">
                                <div className="form-group regarding-payment">
                                  <select
                                    className="col-md-3 col-sm-6 form-control w-50 select2"
                                    name="fromTime"
                                    id="fromTime"
                                    value={paidStatus ? "Paid" : "Pending"}
                                    onChange={handleStatusChange}
                                    style={{ cursor: "pointer" }}
                                    required
                                  >
                                    <option value="Pending">Pending</option>
                                    <option value="Paid">Paid</option>
                                  </select>
                                </div>
                                <div className="form-group regarding-discount">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="note"
                                    onChange={(e) => setNote(e.target.value)}
                                    value={note}
                                    placeholder="Add notes here!"
                                  />
                                </div>
                                <label htmlFor="notes">Other Notes:</label>
                                <div className="form-group regarding-discount">
                                  <textarea
                                    className="form-control"
                                    name="notes"
                                    onChange={(e) =>
                                      setOtherNotes(e.target.value)
                                    }
                                    value={othernotes}
                                    placeholder="Additional comments"
                                    rows={4}
                                  ></textarea>
                                </div>
                              </div>
                              <div className="col-12 col-lg-6 col-md-6 col-xl-5 offset-xl-2">
                                <ul className="list-group cost-list">
                                  <li className="d-flex list-group-item border-0 justify-content-between p-50 each-cost">
                                    <span className="cost-title mr-2">
                                      Subtotal{" "}
                                    </span>
                                    <span className="cost-value">
                                      {currencySymbol} {subtotal.toFixed(2)}
                                    </span>
                                  </li>
                                  <li className="d-flex list-group-item border-0 justify-content-between p-50 each-cost">
                                    <span className="cost-title mr-2">
                                      {invoiceData.admin.tax_label} Included{" "}
                                    </span>
                                    <span className="cost-value">10%</span>
                                  </li>
                                  <li className="d-flex list-group-item border-0 justify-content-between p-50 each-cost">
                                    <span className="cost-title mr-2">
                                      {invoiceData.admin.tax_label} Amount{" "}
                                    </span>
                                    <span className="cost-value">
                                      {currencySymbol} {taxAmount.toFixed(2)}
                                    </span>
                                  </li>
                                  <li className="dropdown-divider" />
                                  <li className="d-flex list-group-item border-0 justify-content-between p-50 each-cost">
                                    <span className="cost-title mr-2">
                                      Invoice Total (Inc.{" "}
                                      {invoiceData.admin.tax_label}){" "}
                                    </span>
                                    <span className="cost-value">
                                      {currencySymbol} {total.toFixed(2)}
                                    </span>
                                  </li>
                                  <li className="d-flex list-group-item border-0 justify-content-between p-50 each-cost">
                                    <span className="cost-title mr-2">
                                      Amount Paid
                                    </span>
                                    <span className="cost-value">
                                      -{currencySymbol}{" "}
                                      {paidStatus ? paidAmt.toFixed(2) : 0}
                                    </span>
                                  </li>
                                  <li className="d-flex list-group-item border-0 justify-content-between p-50 each-cost">
                                    <span className="cost-title mr-2">
                                      Balance{" "}
                                    </span>
                                    <span className="cost-value">
                                      {currencySymbol} {dueAmount.toFixed(2)}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      {!isEdit && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={(e) => handleSubmit(e, invoiceId, true)}
                          disabled={loading || isSavInvoice}
                        >
                          {loading ? "Sending" : "Save and Send"}
                          {loading && (
                            <Spinner
                              size="sm"
                              style={{
                                marginLeft: "5px",
                              }}
                            />
                          )}
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => handleSubmit(e, invoiceId, false)}
                        disabled={isSavInvoice || loading}
                      >
                        Save
                      </button>

                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={onClose}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
          {/* Select Item Modal */}
          <SelectItemModal
            isOpen={isItemSelectModalOpen}
            onClose={() => setIsItemSelectModalOpen(false)}
            onSelectItem={handleSelectItem}
            availableItems={availableItems}
          />
        </div>
      ) : (
        // view Invoice for client
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#DEE6EE" }}
            >
              <h5 className="modal-title">View Invoice</h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={onClose}
              >
                <i className="feather icon-x" aria-hidden="true" />
              </button>
            </div>
            <div
              className="modal-body"
              style={{ overflowX: "hidden", overflowY: "auto" }}
            >
              <div
                style={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }}
              >
                {invoiceData && (
                  <form>
                    <div
                      className="modal-body"
                      style={{ overflowX: "hidden", overflowY: "auto" }}
                    >
                      <div className="card">
                        <div className="card-body">
                          <div className="card-header">
                            <div className="row">
                              {isEdit && (
                                <div className="col-md-12 col-xl-3 d-flex align-items-center justify-content-start pl-0">
                                  <h6 className="font-weight-bold invoice-text mr-1">
                                    Invoice#
                                  </h6>
                                  <p className="" style={{ marginTop: "6px" }}>
                                    {invoiceId}
                                  </p>
                                </div>
                              )}
                              <div className="col-md-12 col-xl-9 d-flex flex-wrap align-items-center align-items-lg-start align-items-sm-start align-items-xs-start justify-content-xl-end pt-1 pt-xl-0 px-0">
                                <div className="d-flex align-items-center justify-content-start issue-date mb-75 mb-xl-0 mr-2">
                                  <h6 className="font-weight-bold invoice-text mr-1">
                                    Date Issue
                                  </h6>
                                  <p style={{ marginTop: "6px" }}>
                                    {formatDate(invoiceData.invoice.updated_at)}
                                  </p>
                                </div>
                                {/* <div className="d-flex align-items-center justify-content-start due-date">
                                  <h6 className="font-weight-bold invoice-text mr-1">
                                    Date Due
                                  </h6>
                                  <p style={{ marginTop: "6px" }}>
                                    {formatDate(new Date(invoiceData.invoice.due_date))}
                                  </p>
                                </div> */}
                              </div>
                            </div>
                            {invoiceData.invoice.due_date ? (
                              <div
                                className="d-flex align-items-center justify-content-end due-date  mb-75 mb-xl-0 "
                                style={{ marginRight: "6px" }}
                              >
                                <h6 className="font-weight-bold invoice-text mr-1">
                                  Date Due
                                </h6>
                                <p style={{ marginTop: "6px" }}>
                                  {formatDate(
                                    new Date(invoiceData.invoice.due_date)
                                  )}
                                </p>
                              </div>
                            ) : (
                              <div
                                className="d-flex align-items-center justify-content-end due-date  mb-75 mb-xl-0 "
                                style={{ marginRight: "6px" }}
                              >
                                <h6 className="font-weight-bold invoice-text mr-1">
                                  Date Due
                                </h6>
                                <p style={{ marginTop: "6px" }}>
                                  {formatDate(
                                    new Date(invoiceData.invoice.updated_at)
                                  )}
                                </p>
                              </div>
                            )}
                          </div>
                          <hr />
                          <div className="row my-2">
                            <div className="col-12 col-sm-6 order-2 order-sm-1">
                              <h4 className="text-primary invoice-title">
                                Invoice
                              </h4>
                              <p>{invoiceData.admin.name}</p>
                            </div>
                            <div className="col-12 col-sm-6 d-flex order-1 order-sm-1 align-items-center justify-content-end">
                              <>
                                {invoiceData.admin.logo ? (
                                  <img
                                    src={`${IMAGE_URL}/${invoiceData.admin.logo}`}
                                    alt="logo"
                                    height="auto"
                                    width={164}
                                  />
                                ) : (
                                  <span>{invoiceData.admin.subdomain}</span>
                                )}
                              </>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-lg-6 col-sm-12 col-xl-6 col-xs-12">
                              <div className="title-text">Invoice To</div>
                              <div className="row">
                                <div className="col-12 col-xs-12 mb-1">
                                  <p className="mt-1">
                                    {invoiceData.client.name}
                                  </p>
                                </div>
                                <div className="title-text ml-1">
                                  Business Name
                                </div>
                                <div className="col-12 col-xs-12 mb-1">
                                  <p className="mt-1">
                                    {invoiceData.client.business_name}
                                  </p>
                                </div>
                                <div className="col-12 col-xs-12">
                                  <p className="mt-1">{clientAddress}</p>
                                </div>
                                <div className="col-12 col-xs-12">
                                  <p className="mt-1">
                                    {invoiceData.client?.invoice_emails
                                      ?.length > 0
                                      ? invoiceData.client.invoice_emails.join(
                                          ", "
                                        )
                                      : invoiceData.client.default_email}
                                  </p>
                                </div>
                                <div className="col-12 col-xs-12">
                                  <p className="mt-1">
                                    {invoiceData.client.phone || ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-lg-6 col-sm-12 col-xl-6 col-xs-12"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div className="row">
                                <div className="col-12 col-xs-12 mb-1">
                                  <div className="font-weight-bold pt-md-5 title-text">
                                    Invoice From:
                                  </div>
                                  {invoiceData.admin.business_name}
                                  <br />
                                  <br />
                                  {invoiceData.admin.address}
                                  <br />
                                  <br />
                                  {invoiceData.admin.email}
                                  <br />
                                  <br />
                                  <span className="font-weight-bold">
                                    ABN:
                                  </span>{" "}
                                  {invoiceData.admin.abn_acn}
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="invoice-product-details">
                            <form className="repeater-form">
                              <div data-repeater-list="group-a">
                                <div className="table-responsive">
                                  <table className="table table-bordered mt-3">
                                    <thead>
                                      <tr>
                                        <th>Item Name</th>
                                        <th>Description</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th>Total</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {items.map((item, index) => (
                                        <tr key={item.id}>
                                          <td>
                                            <p className="mt-1">{item.name}</p>
                                          </td>
                                          <td>
                                            <p className="mt-1">
                                              {item.description}
                                            </p>
                                          </td>
                                          <td>
                                            <p className="mt-1">
                                              {item.quantity}
                                            </p>
                                          </td>
                                          <td>
                                            <p className="mt-1">
                                              {currencySymbol}
                                              {item.price}
                                            </p>
                                          </td>
                                          <td>
                                            <p className="font-weight-bold mt-1">
                                              {currencySymbol}
                                              {item.quantity * item.price}
                                            </p>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </form>
                          </div>
                          <hr />
                          <div className="invoice-total">
                            <div className="row justify-content-between">
                              <div className="col-12 col-lg-6 col-md-6 col-xl-5">
                                <div style={{ width: "50%" }}>
                                  <p
                                    className="font-style-bold"
                                    style={{
                                      margin: 0,
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    Pay To:
                                  </p>
                                  <p
                                    style={{
                                      margin: 0,
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    Name: {invoiceData.admin.account_name}
                                  </p>
                                  <p
                                    style={{
                                      margin: 0,
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    BSB: {invoiceData.admin.bsb_number}
                                  </p>
                                  <p
                                    style={{
                                      margin: 0,
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    Account: {invoiceData.admin.account_number}
                                  </p>
                                  <p
                                    style={{
                                      margin: 0,
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    NOTE: {invoiceData.invoice.notes}
                                  </p>

                                  <p
                                    style={{
                                      margin: 0,
                                      wordWrap: "break-word",
                                      width: "calc(50% - 4px)",
                                    }}
                                  ></p>
                                </div>

                                <div className="d-flex form-group regarding-payment">
                                  <label className="font-weight-bold mr-2">
                                    Status:
                                  </label>
                                  <p className="">
                                    {paidStatus ? "Paid" : "Pending"}
                                  </p>
                                </div>
                                <div className="d-flex form-group regarding-discount">
                                  <label className="font-weight-bold mr-2">
                                    Note:
                                  </label>
                                  {/* <input
                                    type="text"
                                    className="form-control"
                                    name="note"
                                    onChange={(e) => setNote(e.target.value)}
                                    value={note}
                                    placeholder="Add notes here!"
                                  /> */}
                                  <p className="">{note || ""}</p>
                                </div>
                                {/* { invoiceData.invoice.other_notes && (
                                <div className="d-flex form-group regarding-discount">
                                  <label className="font-weight-bold mr-2">
                                    Other Note:
                                  </label>
                                 
                                  <p className="">{ invoiceData.invoice.other_notes || ""}</p>
                                  
                                </div>
                                )} */}
                                {invoiceData.invoice.other_notes && (
                                  <div className="d-flex form-group regarding-discount">
                                    <label className="font-weight-bold mr-2">
                                      Other Note:
                                    </label>
                                    <p className="">
                                      {invoiceData.invoice.other_notes}
                                    </p>
                                  </div>
                                )}
                                {invoiceLink && (
                                  <div className="d-flex form-group regarding-discount">
                                    <label className="font-weight-bold mr-2">
                                      Quickbook Invoice Link
                                    </label>
                                    <a
                                      href={invoiceLink}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {invoiceLink}
                                    </a>
                                  </div>
                                )}
                              </div>
                              <div className="col-12 col-lg-6 col-md-6 col-xl-5 offset-xl-2">
                                <ul className="list-group cost-list">
                                  <li className="d-flex list-group-item border-0 justify-content-between p-50 each-cost">
                                    <span className="cost-title mr-2">
                                      Subtotal{" "}
                                    </span>
                                    <span className="cost-value">
                                      {currencySymbol}
                                      {subtotal.toFixed(2)}
                                    </span>
                                  </li>
                                  <li className="d-flex list-group-item border-0 justify-content-between p-50 each-cost">
                                    <span className="cost-title mr-2">
                                      {invoiceData.admin.tax_label} Included{" "}
                                    </span>
                                    <span className="cost-value">10%</span>
                                  </li>
                                  <li className="d-flex list-group-item border-0 justify-content-between p-50 each-cost">
                                    <span className="cost-title mr-2">
                                      {invoiceData.admin.tax_label} Amount{" "}
                                    </span>
                                    <span className="cost-value">
                                      {currencySymbol}
                                      {taxAmount.toFixed(2)}
                                    </span>
                                  </li>
                                  <li className="dropdown-divider" />
                                  <li className="d-flex list-group-item border-0 justify-content-between p-50 each-cost">
                                    <span className="cost-title mr-2">
                                      Invoice Total{" "}
                                    </span>
                                    <span className="cost-value">
                                      {currencySymbol}
                                      {total.toFixed(2)}
                                    </span>
                                  </li>
                                  <li className="d-flex list-group-item border-0 justify-content-between p-50 each-cost">
                                    <span className="cost-title mr-2">
                                      Amount Paid
                                    </span>
                                    <span className="cost-value">
                                      - {currencySymbol}
                                      {paidStatus ? paidAmt.toFixed(2) : 0}
                                    </span>
                                  </li>
                                  <li className="d-flex list-group-item border-0 justify-content-between p-50 each-cost">
                                    <span className="cost-title mr-2">
                                      Balance{" "}
                                    </span>
                                    <span className="cost-value">
                                      {currencySymbol}
                                      {dueAmount.toFixed(2)}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditInvoiceModal;
