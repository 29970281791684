import React, { useEffect, useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { connectStorageAccount, getUserStorageType } from "../api/storageApi";
import ReTooltip from "../components/Tooltip";

const ConnectStorage = () => {
  const clientId = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;
  const [storageType, setStorageType] = useState({
    google: false,
    dropbox: false,
    aws: false,
  });
  useEffect(() => {
    (async () => {
      const response = await getUserStorageType();
      if (response.status) {
        const storageTypeArray = response.data.split("|");
        const newStorageType = storageTypeArray.reduce((acc, type) => {
          acc[type] = true;
          return acc;
        }, {});
        console.log("storageType :>> ", { ...storageType, ...newStorageType });
        setStorageType((prev) => ({
          ...prev,
          ...newStorageType,
        }));
      }
    })();
  }, []);
  return (
    <div className="d-flex flex-column" style={{ gap: "16px" }}>
      <GoogleOAuthProvider clientId={clientId}>
        <GoogleSetupButton
          storageType={storageType}
          setStorageType={setStorageType}
        />
      </GoogleOAuthProvider>
      <DropboxSetupButton
        storageType={storageType}
        setStorageType={setStorageType}
      />
      <button
        type="button"
        className="btn btn-primary btn-block"
        onClick={() => window.history.back()}
      >
        Back to Dashboard
      </button>
    </div>
  );
};

export default ConnectStorage;

const GoogleSetupButton = ({ storageType, setStorageType }) => {
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect_uri");
  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const response = await connectStorageAccount({
        ...codeResponse,
        type: "google",
      });
      if (response.status) {
        toast.success(response.message);
        setStorageType((prev) => ({ ...prev, google: true }));
        setTimeout(() => {
          window.location.href = redirectUrl;
        }, 2000);
      } else {
        toast.error(response.message);
      }
    },
    onError: (error) => {
      console.log("Login Failed:", error);
      toast.error("Login Failed: " + error);
    },
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/drive",
    access_type: "offline",
    select_account: true,
  });

  return (
    <div>
      <ReTooltip
        title={`Click to ${
          storageType.google ? "Update" : "Connect"
        } your Google Account`}
        placement="top"
      >
        <button
          type="button"
          className="btn btn-outline-primary btn-block"
          onClick={() => login()}
        >
          {storageType.google
            ? "Google Account is Connected"
            : "Connect Google"}
        </button>
      </ReTooltip>
    </div>
  );
};

const DropboxSetupButton = ({ storageType, setStorageType }) => {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const redirectUrl = searchParams.get("redirect_uri");

  let url; // Declare `url` outside the conditional block

  if (redirectUrl) {
    url = new URL(redirectUrl); // Assign a value to `url`
    url.searchParams.set("userId", userId);
  }
  const REACT_APP_DROPBOX_CLIENT = process.env.REACT_APP_DROPBOX_CLIENT;
  const REACT_APP_DROPBOX_REDIRECT = process.env.REACT_APP_DROPBOX_REDIRECT;

  const scopes = encodeURIComponent(
    "account_info.read files.metadata.write files.metadata.read files.content.write files.content.read sharing.write sharing.read file_requests.write file_requests.read"
  );

  const dropboxAuthUrl = `https://www.dropbox.com/oauth2/authorize?client_id=${REACT_APP_DROPBOX_CLIENT}&redirect_uri=${REACT_APP_DROPBOX_REDIRECT}&token_access_type=offline&scope=${scopes}&response_type=code&state=${
    url || ""
  }`;

  return (
    <>
      {storageType.dropbox ? (
        <div
          className={
            "btn btn-outline-primary btn-block" +
            (storageType.dropbox ? "cursor-not-allowed" : "")
          }
        >
          Dropbox Account is Connected
        </div>
      ) : (
        <Link
          to={`${dropboxAuthUrl}`}
          className="btn btn-outline-primary btn-block"
          style={{ paddingTop: "10px" }}
        >
          Link Your Dropbox
        </Link>
      )}
    </>
  );
};
