import React, { createContext, useState, useContext, useEffect } from "react";
import { updateStatus } from "../api/authApis";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(() => {
    const storedIsAuth = localStorage.getItem("isAuth") === "true";
    const storedUser = JSON.parse(localStorage.getItem("user"));
    const storedToken = localStorage.getItem("accessToken");
    return { isAuth: storedIsAuth, user: storedUser, token: storedToken };
  });

  useEffect(() => {
    const storedIsAuth = localStorage.getItem("isAuth") === "true";
    const storedUser = JSON.parse(localStorage.getItem("user"));
    const storedToken = localStorage.getItem("accessToken");
    setAuthData({ isAuth: storedIsAuth, user: storedUser, token: storedToken });
  }, []);

  const setAuthStatus = ({ user, token }) => {
    const newIsAuth = !!user && !!token;
    localStorage.setItem("isAuth", newIsAuth);

    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("accessToken", token);

    document.cookie = `isAuth=${newIsAuth}; domain=.localhost; path=/`;
    document.cookie = `user=${JSON.stringify(user)}; domain=.localhost; path=/`;
    document.cookie = `accessToken=${token}; domain=.localhost; path=/`;

    setAuthData({ isAuth: newIsAuth, user, token });
  };

  const setAuthUser = (user) => {
    setAuthData((prevState) => ({
      ...prevState,
      user,
    }));

    // Update cookies
    document.cookie = `user=${JSON.stringify(user)}; domain=.localhost; path=/`;
  };

  const logout = async () => {
    try {
      localStorage.removeItem("isAuth");
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");

      // Remove cookies
      document.cookie =
        "isAuth=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      document.cookie = "user=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      document.cookie =
        "accessToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";

      setAuthData({ isAuth: false, user: null, token: null });
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{ authData, setAuthStatus, logout, setAuthUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};
