// Header.js
import React, { useEffect, useState } from "react";
import { useAuth } from "../context/authContext";
import logoGreen from "../assets/images/studiio-au-Green.png";
import { userStatusCheck } from "../api/clientApis";
import { Link, useNavigate } from "react-router-dom";
import SideNav from "./SideNav";
import { getAllBookings } from "../api/bookingApis";
import { updateStatus } from "../api/authApis";
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const Header = () => {
  const navigate = useNavigate();
  const { authData, logout } = useAuth();
  const { user } = authData;
  const subdomainId = user.subdomain_id;
  const roleId = user.role_id;
  const [showSidebar, setShowSidebar] = useState(false);
  const [isMenuExpanded, setIsMenuExpanded] = useState(true);
  const [hovering, setHovering] = useState(false);
  const [bookingsData, setBookingsData] = useState([]);
  const userId = authData.user ? authData.user.id : null;

  const handleLogout = async (e) => {
    localStorage.removeItem("favEmailSet");
    e.preventDefault();

    if(roleId === 5){
       await updateStatus(userId);
    }

    logout();

    navigate("/login");
  };

  useEffect(() => {
    checkUserStatus();
  }, []);

  const checkUserStatus = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("id", user.id);
      let res = await userStatusCheck(formDataToSend);
      if (res.data.status === "Inactive") {
        await logout();
        navigate("/login");
      }
    } catch (error) {
      console.error("Error checking user status:", error);
    }
  };

  const handleMenuClick = () => {
    setShowSidebar((prev) => !prev);
  };

  const toggleMenu = () => {
    setIsMenuExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    const datatosend = {
      subdomainId: subdomainId,
      roleId: roleId,
      userId: userId,
    };

    const fetchBookingsData = async () => {
      try {
        const response = await getAllBookings(datatosend);
        setBookingsData(response.data);
      } catch (error) {
        console.error("Error fetching bookings data:", error);
      }
    };

    fetchBookingsData();
  }, [subdomainId, roleId, userId]);

  useEffect(() => {
    const body = document.querySelector("body");
    if (isMenuExpanded === true) {
      body.classList.add("menu-expanded");
      body.classList.remove("menu-collapsed");
    } else {
      body.classList.add("menu-collapsed");
      body.classList.remove("menu-expanded");
    }
  }, [isMenuExpanded]);

  const notificationCount = bookingsData.filter(
    (booking) => booking.booking_status === 0
  ).length;

  // useEffect(() => {
  //   const img = user.profilePhoto
  //     ? `${IMAGE_URL}/${user.profilePhoto}?${new Date().getTime()}`
  //     : `../assets/images/dummy.png?t=${new Date().getTime()}`;

  //   // Check if the image URL is valid by trying to load it
  //   const imgElement = new Image();
  //   imgElement.src = img;

  //   imgElement.onload = () => {
  //     setImgUrl(img); // If the image loads successfully, set the valid image URL
  //   };

  //   imgElement.onerror = () => {
  //     setImgUrl(`../assets/images/dummy.png?t=${new Date().getTime()}`); // If the image fails to load, set the dummy image
  //   };

  //   // Set the image URL to either valid or dummy based on the result of the image check
  // }, [user.profilePhoto]);

  return (
    <>
      <nav className="header-navbar navbar-expand-lg navbar navbar-with-menu fixed-top navbar-semi-dark navbar-shadow">
        <div className="navbar-wrapper">
          <div
            className={`navbar-header ${hovering ? "expanded" : ""}`}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
          >
            <ul className="nav navbar-nav flex-row">
              <li className="nav-item mobile-menu d-lg-none mr-auto">
                <a
                  className="nav-link nav-menu-main menu-toggle hidden-xs"
                  href="#"
                  onClick={handleMenuClick}
                >
                  <i className="feather icon-menu font-large-1" />
                </a>
              </li>
              <li className="nav-item mr-auto">
                <span
                  className="navbar-brand"
                  onClick={() => navigate("/dashboard")}
                >
                  <img
                    className="brand-logo dropLogo"
                    alt="stack admin logo"
                    src={logoGreen}
                  />
                </span>
              </li>

              <li className="nav-item d-none d-lg-block nav-toggle">
                <a
                  className="nav-link modern-nav-toggle pr-0"
                  onClick={toggleMenu}
                >
                  {isMenuExpanded ? (
                    <i
                      className="toggle-icon feather icon-toggle-right font-medium-3 white"
                      data-ticon="feather.icon-toggle-right"
                    ></i>
                  ) : (
                    <i
                      className="toggle-icon font-medium-3 white feather icon-toggle-left"
                      data-ticon="feather.icon-toggle-right"
                    ></i>
                  )}
                </a>
              </li>

              <li className="nav-item d-lg-none">
                <a
                  className="nav-link open-navbar-container"
                  data-toggle="collapse"
                  data-target="#navbar-mobile"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="navbar-container content">
            <div className="collapse navbar-collapse" id="navbar-mobile">
              <ul className="nav navbar-nav mr-auto float-left d-flex align-items-center"></ul>
              <ul className="nav navbar-nav float-right">
                <li className="dropdown dropdown-user nav-item d-flex align-items-center justify-content-center">
                  <div className="notification-icon">
                    <a className="nav-link" href="/booking-list-calendar">
                      <i className="feather icon-bell"></i>
                      {notificationCount > 0 && (
                        <span className="badge badge-pill badge-danger">
                          {notificationCount}
                        </span>
                      )}
                    </a>
                  </div>
                  <a
                    className="dropdown-toggle nav-link dropdown-user-link"
                    href="#"
                    data-toggle="dropdown"
                  >
                    <div className="avatar avatar-online">
                      <img
                        src={
                          user.profilePhoto
                            ? `${IMAGE_URL}/${user.profilePhoto}`
                            : `../assets/images/dummy.png`
                        }
                        onError={(e) => {
                          e.target.src = `../assets/images/dummy.png`;
                          e.target.onerror = null;
                        }}
                        style={{ width: "50px", height: "30px" }}
                        alt="profile"
                      />
                      <i></i>
                    </div>
                    <span className="user-name">{user.userName}</span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right shadow-lg">
                    {(roleId === 2 ||
                      roleId === 3 ||
                      roleId === 4 ||
                      roleId === 5) && (
                      <>
                        <a className="dropdown-item" href="/edit-profile">
                          <i className="feather icon-user"></i> Edit Profile
                        </a>
                        <div className="dropdown-divider"></div>
                      </>
                    )}
                    <button
                      className="dropdown-item"
                      href="#"
                      onClick={handleLogout}
                    >
                      <i className="feather icon-power"></i> Logout
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <SideNav
        showSidebar={showSidebar}
        hovering={hovering}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        isMenuExpanded={isMenuExpanded}
        handleMenuClick={handleMenuClick}
      />
    </>
  );
};

export default Header;
