import React from "react";
import { useParams } from "react-router-dom";
import { useTable } from "react-table";
import useCountrySelect from "../../hooks/useCountrySelect";
import { useAuth } from "../../context/authContext";
const ClientsTable = ({ client }) => {
  const { id } = useParams();
  const { authData, setAuthUser } = useAuth();
  const user = authData.user;
  const { countryOptions } = useCountrySelect();
  const userCountry = user.role_id === 5 ? user.country : user.adminCountry;
  const countryData = countryOptions.find((c) => c.value === userCountry);
  const currencySymbol = countryData ? countryData.currencySymbol : "$";

  const formatDate = (datestring) => {
    if (!datestring) return "";
    return new Date(datestring).toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Invoice #",
        accessor: "id",
        Cell: ({ value }) => (
          <span
            style={{
              fontWeight: "bold", // ✅ Forces bold
              color: "rgb(21, 91, 255)", // ✅ Forces blue color
            }}
          >
            Invoice {value}
          </span>
        ),
      },

      {
        Header: "Invoice Date",
        accessor: "invoice_date",
        id: "invoice_date_1",
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        id: "invoice_date_2",
        Cell: ({ value, row }) => (
          <div>
            {value ? formatDate(value) : formatDate(row.original.invoice_date)}
            <br /> {/* This forces "OverDue" to the next line */}
            <span style={{ color: "red", fontWeight: "bold" }}>OverDue</span>
          </div>
        ),
      },
      {
        Header: "Total",
        accessor: "total_price",
        Cell: ({ value }) => `${currencySymbol}${parseFloat(value).toFixed(2)}`,
      },
      {
        Header: "Paid",
        accessor: "paid_amount",
        Cell: ({ value }) => `${currencySymbol}${parseFloat(value).toFixed(2)}`,
      },
      {
        Header: "Due",
        id: "computed_due",
        Cell: ({ row }) => {
          const total = parseFloat(row.original.total_price) || 0;
          const paid = parseFloat(row.original.paid_amount) || 0;
          const due = total - paid;
          return `${currencySymbol}${due.toFixed(2)}`;
        },
      },
    ],
    []
  );
  const tableInstance = useTable({ columns, data: client });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  return (
    <div>
      <div className="table-responsive">
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ClientsTable;
