import React, { useEffect, useRef, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Button, Checkbox, Switch } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  activeInactiveClient,
  createClient,
  deleteClient,
  getClient,
  sendWelcomeEmail,
} from "../../api/clientApis";
import { toast } from "react-toastify";
import { useAuth } from "../../context/authContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Invoice from "../../pages/Invoice";
import { Table } from "react-bootstrap";
import ClientInvoice from "./TableInvoiceClient";
import InvoiceClient from "./InvoiceClient";
import DeleteModal from "../DeleteModal";
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

const AddOrEditClientForm = ({ location }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { authData } = useAuth();
  const user = authData.user;

  const subdomainId = user.subdomain_id;
  const [key, setKey] = useState("client-details");
  const [previewImage, setPreviewImage] = useState(null);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
    business_name: "",
    password: "",
    profile_photo: "",
    default_invoice_email: "",
    send_welcome_email: false,
    invoice_emails: "",
  });
  const [emails, setEmails] = useState([]);
  const [invoiceEmails, setInoviceEmails] = useState([
    { invoice_email: "", new: true }
  ]);

  const [permission, setPermission] = useState({
    calendar: { label: "View Calendar", value: true },
    booking: {
      label: "View Blanked out Bookings",
      value: true,
    },
    invoice: {
      label: "View Invoice",
      value: true,
    },
    service: {
      label: "View Service",
      value: true,
    },
  });
  const [managePassword, setManagePassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);

  const fileInputRef = useRef(null);
  const removedEmailsRef = useRef([]);
  const removedInvoiceEmailsRef = useRef([]);

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        setFormData({
          ...formData,
          profile_photo: file,
        });
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
      setFormData({
        ...formData,
        profile_photo: "",
      });
    }
  };

  // const handleStatusChange = async () => {
  //   try {
  //     const formDataToSend = new FormData();
  //     formDataToSend.append("id", formData.id);
  //     formDataToSend.append("subdomainId", subdomainId);
  //     const newStatus = checked ? "Inactive" : "Active";
  //     formDataToSend.append("status", newStatus);
  //     let res = await activeInactiveClient(formDataToSend);
  //     if (res.success) {
  //       setChecked(!checked);
  //     }
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleEmailChange = (value, index) => {
    const updatedEmails = [...emails];
    updatedEmails[index].email = value;
    setEmails(updatedEmails);
  };

  const handleInvoiceEmailChange = (value, index) => {
    setInoviceEmails((prev) => {
      if (prev.length === 0) {
        console.error("Invoice Emails list is empty, initializing...");
        return [{ invoice_email: value, new: true }];
      }
  
      // Ensure the index exists before updating
      if (index >= prev.length || index < 0) {
        console.error("Invalid index:", index);
        return prev; // Return unchanged state
      }
  
      const updatedInvoiceEmails = [...prev];
      updatedInvoiceEmails[index] = {
        ...updatedInvoiceEmails[index], // Preserve existing properties
        invoice_email: value, // Update email
      };
  
      return updatedInvoiceEmails;
    });
  };
  

  const handleAddEmails = () => {
    if (emails.length < 3) {
      setEmails([
        ...emails,
        {
          email: "",
          new: true,
        },
      ]);
    }
  };
  const handleInoviceAddEmails = () => {
    if (invoiceEmails.length < 4) {
      setInoviceEmails([
        ...invoiceEmails,
        {
          invoice_email: "",
          new: true,
        },
      ]);
    }
  };
  const handleRemoveEmail = (index) => {
    const updatedEmails = [...emails];
    removedEmailsRef.current.push(updatedEmails[index]);
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
  };
  // const handleInvoiceRemoveEmail = (index) => {
  //   const updatedInvoiceEmails = [...invoiceEmails];
  //   removedInvoiceEmailsRef.current.push(updatedInvoiceEmails[index]);
  //   updatedInvoiceEmails.splice(index, 1);
  //   setInoviceEmails(updatedInvoiceEmails);
  // };
  const handleInvoiceRemoveEmail = (index) => {
    setInoviceEmails((prev) => {
      const updatedInvoiceEmails = [...prev];
      const removedEmail = updatedInvoiceEmails.splice(index, 1)[0];
  
      if (!removedEmail.new) {
        removedInvoiceEmailsRef.current.push(removedEmail);
      }
  
      console.log("Removed emails:", removedInvoiceEmailsRef.current);
      return updatedInvoiceEmails;
    });
  };

  const handlePermissionChange = (key, value) => {
    setPermission((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        value,
      },
    }));
  };

  const handleManagePasswordChange = (e) => {
    const { name, value } = e.target;
    setManagePassword((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (id) {
      const getClientData = async () => {
        try {
          const formDataToSend = new FormData();
          formDataToSend.append("id", id);
          let clientData = await getClient(formDataToSend);
          if (clientData.data.profile_photo !== "") {
            let path = `${IMAGE_URL}/${clientData.data.profile_photo}`;
            setPreviewImage(path);
          } else {
            setPreviewImage(null);
          }
          if (clientData.data.status === "Active") {
            setChecked(true);
          } else {
            setChecked(false);
          }
          if (clientData.data.permissions) {
            const newPermission = JSON.parse(JSON.stringify(permission));
            Object.keys(newPermission).forEach((key) => {
              if (clientData.data.permissions[key]) {
                newPermission[key].value = clientData.data.permissions[key];
              } else {
                newPermission[key].value = false;
              }
            });
            setPermission(newPermission);
          } else {
            const newPermission = JSON.parse(JSON.stringify(permission));
            Object.keys(newPermission).forEach((key) => {
              newPermission[key].value = true;
            });
            setPermission(newPermission);
          }
          setEmails(
            clientData.data.emails.map((email) => ({
              email: email.email,
              new: false,
            }))
          );
          
          setInoviceEmails(
            clientData.data.otherEmails.filter(
              (email) => !removedInvoiceEmailsRef.current.includes(email.email)
            ).map((email) => ({
              invoice_email: email.email,
              new: false,
            }))
          );

          setFormData(clientData.data);
        } catch (error) {
          toast.error(error);
        }
      };
      getClientData();
    }
  }, [id]);

  const resetFormData = async () => {
    setFormData({
      id: "",
      name: "",
      email: "",
      phone: "",
      business_name: "",
      profile_photo: null,
      password: "",
      reset_password: false,
    });
    setPreviewImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("subdomainId", subdomainId);
      formDataToSend.append("id", formData.id);
      formDataToSend.append("name", formData.name);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("phone", formData.phone);
      formDataToSend.append("business_name", formData.business_name);
      formDataToSend.append("password", formData.password);
      formDataToSend.append("profile_photo", formData.profile_photo);
      formDataToSend.append("invoice_emails", JSON.stringify(invoiceEmails));
      // console.log("Form Data to Send:", formDataToSend);
      // formDataToSend.append("invoice_emails", formData.invoice_emails);
      // formDataToSend.append("invoice_emails", JSON.stringify(invoiceEmails));
      formDataToSend.append("role_id", 3);
      formDataToSend.append(
        "default_invoice_email",
        formData.default_invoice_email
      );
      // formDataToSend.append("secondemails",formData.secondemails);
      formDataToSend.append("send_welcome_email", formData.send_welcome_email);
      formDataToSend.append("reset_password ", isPasswordChange);
      formDataToSend.append(
        "permissions",
        JSON.stringify(
          Object.keys(permission).reduce((acc, key) => {
            acc[key] = permission[key].value;
            return acc;
          }, {})
        )
      );
      formDataToSend.append("emails", JSON.stringify(emails));
      formDataToSend.append(
        "removedEmails",
        JSON.stringify(removedEmailsRef.current)
      );

      formDataToSend.append(
        "removedInvoiceEmails",
        JSON.stringify(removedInvoiceEmailsRef.current)
      );
      // console.log("FormData to Send:", Object.fromEntries(formDataToSend)); // Log formatted formDataToSend
      
      let res = await createClient(formDataToSend);
      if (res.success) {
        toast.success(res.message);
        resetFormData();
        navigate("/clients");
      } else {
        toast.error(res);
      }
    } catch (error) {
      toast.error(error);
    }
    setLoading(false);
  };

  const handleResendEmail = async () => {
    try {
      const resposne = await sendWelcomeEmail({
        subdomainId,
        client_id: formData.id,
      });
      if (resposne.success) {
        toast.success("Email sent successfully!");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const handleArchive = async () => {
    setLoading(true);
    try {
      const response = await deleteClient({
        id: id,
      });
      if (response.success) {
        toast.success(response.message);
        navigate("/clients");
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <>
      <section className="app-content card">
        <div className="card-body">
          <Tabs
            id="manage-client-tabs"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-1"
          >
            <Tab eventKey="client-details" title="Client details">
              <div>
                <form onSubmit={handleSubmit}>
                  <div className="d-flex" style={{ gap: "40px" }}>
                    <div
                      className="d-flex flex-wrap align-items-center"
                      style={{
                        gap: "20px",
                      }}
                    >
                      <div className="">
                        {previewImage ? (
                          <img
                            src={previewImage}
                            alt="Avatar"
                            className="img-fluid rounded-circle"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <img
                            src="../assets/images/dummy.png"
                            alt="Avatar"
                            className="img-fluid rounded-circle"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </div>
                      <div>
                        <div className="d-flex gap-6">
                          <button
                            className="btn btn-primary btn-sm me-2 mr-1"
                            onClick={() => fileInputRef.current.click()}
                            type="button"
                          >
                            Change
                          </button>
                          <button
                            className="btn btn-secondary btn-sm "
                            type="button"
                            onClick={() => {
                              setPreviewImage(null);
                              setFormData({
                                ...formData,
                                profile_photo: "",
                              });
                            }}
                          >
                            Reset
                          </button>
                          <input
                            type="file"
                            className="form-control-file"
                            name="profile_photo"
                            onChange={handlePhotoChange}
                            accept="image/*"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        "text-left " + (checked ? " text-primary" : "")
                      }
                      style={{ marginLeft: "auto" }}
                    >
                      {checked ? "Active" : "Inactive"}
                      <Switch
                        checked={checked}
                        onChange={() => {
                          setChecked(!checked);
                        }}
                        inputProps={{
                          "aria-label": "controlled",
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <div className="mb-2">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          // id="name"
                          name="name"
                          placeholder="Dean Stanley"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-2">
                        <label htmlFor="company" className="form-label">
                          Company
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          // id="company"
                          placeholder="Company name"
                          name="business_name"
                          value={formData.business_name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-2" >
                        <label
                          htmlFor="default_invoice_email"
                          className="form-label"
                        >
                          Accounts Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="deanstanley@gmail.com"
                          name="default_invoice_email"
                          value={formData.default_invoice_email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 ">
                      <div className="mb-2">
                        <label htmlFor="phone" className="form-label">
                          Phone
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Enter phone number"
                          name="phone"
                          value={formData.phone}
                          maxLength="10"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div
                        className="flex-grow-1 "
                        style={{
                          marginBottom:
                            location.pathname === "/add-client"
                              ? "24px"
                              : "11px",
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          <label htmlFor="email" className="form-label">
                            E-mail
                          </label>
                        </div>
                        <div>
                          <div
                            className="d-flex align-items-center"
                            style={{
                              gap: "10px",
                            }}
                          >
                            <input
                              type="email"
                              className="form-control"
                              // id="email"
                              placeholder="deanstanley@gmail.com"
                              name="email"
                              value={formData.email}
                              onChange={handleInputChange}
                              required
                            />
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleAddEmails}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </button>
                          </div>
                          {emails.map((email, index) => (
                            <div
                              className="d-flex align-items-center mt-2"
                              style={{
                                gap: "10px",
                              }}
                            >
                              <input
                                type="email"
                                className="form-control"
                                placeholder="deanstanley@gmail.com"
                                name="email"
                                value={email.email}
                                onChange={(e) =>
                                  handleEmailChange(e.target.value, index)
                                }
                                required
                              />
                              <button
                                type="button"
                                class="btn btn-danger"
                                onClick={() => {
                                  handleRemoveEmail(index);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrashCan} />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex ">
                      <div
                        className="flex-grow-1 "
                        style={{
                          marginBottom:
                            location.pathname === "/add-client"
                              ? "24px"
                              : "11px",
                        }}
                      >
                        {/* -------- */}
                        <div className="d-flex justify-content-between">
                          <label htmlFor="email" className="form-label">
                            Invoice Emails
                          </label>
                        </div>
                        <div>
                          <div
                            className="d-flex align-items-center"
                            style={{
                              gap: "10px",
                            }}
                          >
                            <input
                              type="email"
                              className="form-control"
                              // id="email"
                              name="invoice_emails"
                              placeholder="deanstanley@gmail.com"
                              value={invoiceEmails.length > 0 ? invoiceEmails[0].invoice_email : ""}
                              onChange={(e) =>
                                handleInvoiceEmailChange(e.target.value, 0)
                              }
                              required
                            />
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleInoviceAddEmails}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </button>
                          </div>
                          <div className="mt-2">
                            {invoiceEmails
                              .slice(1)
                              .map((invoiceEmail, index) => (
                                <div
                                  className="d-flex align-items-center mt-2"
                                  style={{
                                    gap: "10px",
                                  }}
                                >
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="deanstanley@gmail.com"
                                    name="invoice_email"
                                    value={invoiceEmail.invoice_email}
                                    onChange={(e) =>
                                      handleInvoiceEmailChange(
                                        e.target.value,
                                        index + 1
                                      )
                                    }
                                    required
                                  />
                                  <button
                                    type="button"
                                    class="btn btn-danger"
                                    onClick={() => {
                                      handleInvoiceRemoveEmail(index + 1);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrashCan} />
                                  </button>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ------------------  */}

                    {location.pathname.startsWith("/edit-client") && (
                      <div className="col-md-6 mt-3 ">
                        <div
                          className={"text-left text-primary"}
                          style={{ marginRight: "10px" }}
                        >
                          Want to change password ?
                          <Switch
                            checked={isPasswordChange}
                            onChange={() => {
                              setIsPasswordChange(!isPasswordChange);
                            }}
                            inputProps={{
                              "aria-label": "controlled",
                            }}
                          />
                        </div>
                        {isPasswordChange && (
                          <div className="mb-2 ">
                            <label htmlFor="password" className="form-label">
                              Password
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter password"
                              name="password"
                              value={formData.password}
                              onChange={handleInputChange}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {location.pathname === "/add-client" && (
                      <div className="col-md-6 mt-3">
                        <div className="mb-2">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            placeholder="Enter password"
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Permissions Table */}
                  <div className="table-responsive">
                    <table className="table mt-4">
                      <thead>
                        <tr>
                          <th>Permission</th>
                          <th>off / on</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(permission).map((key) => (
                          <tr key={key}>
                            <td>{permission[key].label}</td>
                            <td>
                              <Checkbox
                                name="permission"
                                //   value={key}
                                checked={permission[key].value}
                                onChange={() =>
                                  handlePermissionChange(
                                    key,
                                    !permission[key].value
                                  )
                                }
                                color="primary"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="d-flex justify-content-end align-items-center"
                    style={{
                      gap: "10px",
                    }}
                  >
                    {location.pathname === "/add-client" && (
                      <div
                        className={"text-left text-primary"}
                        style={{ marginRight: "10px" }}
                      >
                        Send welcome email
                        <Switch
                          checked={formData.send_welcome_email}
                          onChange={() => {
                            setFormData({
                              ...formData,
                              send_welcome_email: !formData.send_welcome_email,
                            });
                          }}
                          inputProps={{
                            "aria-label": "controlled",
                          }}
                        />
                      </div>
                    )}
                    <button type="submit" className="btn btn-primary">
                      {location.pathname === "/add-client"
                        ? "Add client"
                        : "Save changes"}
                    </button>
                    <Link
                      to="/clients"
                      type="button"
                      className="btn btn-secondary"
                    >
                      cancel
                    </Link>
                  </div>
                </form>
              </div>
            </Tab>
            {location.pathname.startsWith("/edit-client") && (
              <Tab eventKey="accounts" title="Invoices">
                {/* <ClientInvoice /> */}
                <InvoiceClient />
              </Tab>
            )}
          </Tabs>
        </div>
      </section>
      {/* {location.pathname.startsWith("/edit-client") && (
        <section className="app-content card">
          <div className="card-header">
            <h3 className="card-title">Password</h3>
          </div>
          <div className="card-body">
            <div className="row align-items-center">
              <div className="col-md-3">
                <div className="mb-2">
                  <label htmlFor="old_password" className="form-label">
                    Old Password
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="old_password"
                    value={managePassword.old_password}
                    onChange={handleManagePasswordChange}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-2">
                  <label htmlFor="new_password" className="form-label">
                    New Password
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="new_password"
                    value={managePassword.new_password}
                    onChange={handleManagePasswordChange}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="mb-2">
                  <label htmlFor="confirm_password" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="confirm_password"
                    value={managePassword.confirm_password}
                    onChange={handleManagePasswordChange}
                  />
                </div>
              </div>
              <div className="col-md-3 d-flex justify-content-end">
                <button
                  className="text-white btn"
                  style={{
                    backgroundColor: "orange",
                  }}
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </section>
      )}     */}

      {location.pathname.startsWith("/edit-client") &&
        key === "client-details" && (
          <section className="app-content card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-2">
                  <p className="text-uppercase">resend welcome letter</p>
                </div>
                <button
                  className="btn btn-primary text-uppercase"
                  onClick={handleResendEmail}
                >
                  resend invite
                </button>
              </div>
              <div className="row align-items-center mt-2">
                <div className="col-md-2">
                  <p className="text-uppercase">remove account</p>
                </div>
                <button
                  className="btn btn-danger text-uppercase "
                  onClick={() => setShowArchiveModal(true)}
                >
                  archive
                </button>
              </div>
            </div>
          </section>
        )}

      <DeleteModal
        isOpen={showArchiveModal}
        onClose={() => setShowArchiveModal(false)}
        onConfirm={handleArchive}
        message="Are you sure you want to archive this client?"
        label="Archive"
      />
    </>
  );
};

export default AddOrEditClientForm;
