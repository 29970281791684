import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getClientStatemenet } from "../../api/invoiceApis";
import { sendClientStatement } from "../../api/clientApis";
import { useAuth } from "../../context/authContext";
import { toast } from "react-toastify";
import { Font, pdf, PDFDownloadLink } from "@react-pdf/renderer";
import StatementPDF from "../StatementPDF";
import ClientsTable from "./ClientsTable";
import useCountrySelect from "../../hooks/useCountrySelect";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
      fontWeight: 700,
    },
  ],
});

const CreateStatementModal = ({
  isOpen,
  onClose,
  onConfirm,
  message,
  clientdetails,
}) => {
  const { id } = useParams();
  const [clientinvoiceslist, setClientInvoiceList] = useState([]);
  console.log("🚀 ~ clientinvoiceslist:", clientinvoiceslist.filter((i) => i.due_date !== null))
  const [totalOutstanding, setTotalOutStanding] = useState(0);
  const [totalNotYetDue, setTotalNotYetDue] = useState(0);

  const { authData } = useAuth();
  const { user } = authData;
  const subdomainId = user.subdomain_id;
  const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
  const { countryOptions, selectedCountry } = useCountrySelect(
    user.role_id === 5 ? user.country : user.adminCountry
  );
  const userCountry = user.role_id === 5 ? user.country : user.adminCountry;

  const countryData = countryOptions.find((c) => c.value === userCountry);

  const currencySymbol = countryData ? countryData.currencySymbol : "$";

  const clientlist = async () => {
    try {
      const response = await getClientStatemenet({ user_id: id });
      if (!response.error) {
        setClientInvoiceList(response.data);
      } else {
        setClientInvoiceList([]);
      }
    } catch (error) {
      console.error("Error fetching invoice list:", error);
    }
  };
  useEffect(() => {
    clientlist();
  }, [id]);
  const formatDate = (dateString) => {
    if (!dateString) return ""; // Handle empty cases
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "short", // "Feb"
      day: "2-digit", // "18"
      year: "numeric", // "2025"
    });
  };

  useEffect(() => {
    const total = clientinvoiceslist.reduce(
      (sum, client) => sum + (parseFloat(client.total_price) || 0),
      0
    );
    const notYetDue = clientinvoiceslist.reduce(
      (sum, client) => sum + (parseFloat(client.paid_amount) || 0),
      0
    );
    setTotalNotYetDue(notYetDue);
    setTotalOutStanding(total);
  }, [clientinvoiceslist]);

  const handleSendStatement = async () => {
    try {
      const statementBlob = await pdf(
        <StatementPDF
          clientdetails={clientdetails}
          clientinvoiceslist={clientinvoiceslist}
          totalOutstanding={totalOutstanding}
          notYetDue={totalNotYetDue}
          currencySymbol={currencySymbol}
          selectedCountry={selectedCountry}
        />
      ).toBlob();
      console.log("statementBlob", statementBlob);

      if (statementBlob) {
        // Append to FormData
        const formData = new FormData();
        formData.append("statement", statementBlob, "statement.pdf");
        formData.append("user_id", id);
        formData.append("subdomain_id", subdomainId);

        // Send API request
        const response = await sendClientStatement(formData);
        if (!response.error) {
          console.log("response", response);
          toast.success(response.message);
        } else {
          console.error("API Error:", response.error);
        }
      }
    } catch (error) {
      console.error("Error generating and sending PDF:", error);
    }
  };

  return (
    <div
      className={`modal fade ${isOpen ? "show" : ""}`}
      id="staticBackdrop"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-lg shadow-md">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            //   onClick={handleAppointmentModalClose}
            style={{
              position: "absolute",
              right: "-6px",
              zIndex: 9,
              transform: "translate(20%, -50%)",
              outline: "none",
              backgroundColor: "#00b5b8",
              opacity: "1",
              padding: "6px",
              borderRadius: "50%",
            }}
          >
            <FontAwesomeIcon icon={faXmark} color="white" width={21} />
          </button>
          <div id="print-content">
            <div className="modal-body">
              <div className="row mb-3">
                {/* Logo Column */}
                {clientdetails.logo ? (
                  <div className="col-12 col-md-3 d-flex justify-content-end justify-content-md-start p-2">
                    <div>
                      <img
                        src={`${IMAGE_URL}/${clientdetails.logo}`}
                        alt=""
                        className="img-fluid rounded-circle"
                        style={{
                          width: "175px",
                          height: "175px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {/* Address Details Column */}
                <div
                  className={`p-2 ${
                    clientdetails.logo ? "col-12 col-md-4" : "col-12 col-md-7  "
                  }`}
                >
                  <span style={{ fontWeight: "bold" }}>
                    {clientdetails.business_name}
                  </span>
                  <div>{clientdetails.address}</div>
                  <div>{clientdetails.city}</div>
                  <div>{clientdetails.country}</div>
                </div>

                {/* Title Column */}
                <div className="col-12 col-md-5 p-2 d-flex flex-column align-items-center align-items-md-end text-end ms-auto">
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "25px",
                      textAlign: "right",
                      width: "100%",
                    }}
                    className="d-block"
                  >
                    Statement of Account
                  </span>
                  <div>
                    <span style={{ fontSize: "18px" }}>
                      Outstanding invoices
                    </span>
                  </div>
                </div>
              </div>

              {/* Bill To Section */}
              <div className="row mb-3">
                <div className="col-12 col-md-6 p-2">
                  <div>Bill to</div>
                  <div style={{ fontWeight: "bold" }}>{clientdetails.name}</div>
                </div>

                <div className="col-12 col-md-6 p-2 d-flex flex-column align-items-end align-items-sm-end  text-end  ">
                  <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                    {selectedCountry
                      ? `${selectedCountry.label} ${selectedCountry.label3} (${selectedCountry.label2})`
                      : "Select a country"}
                  </span>
                  <div className="p-1" style={{ fontSize: "15px" }}>
                    As of {formatDate(new Date())}
                  </div>

                  {/* Line Separator */}
                  <div
                    className="w-100 border-bottom"
                    style={{
                      borderColor: "rgba(177, 175, 175, 0.5)",
                    }}
                  ></div>

                  {/* Amounts - Overdue & Not Yet Due */}
                  <div
                    className="d-flex flex-row "
                    style={{ fontSize: "15px" }}
                  >
                    <div className="p-2 d-flex flex-column text-end align-items-end align-items-sm-end  text-end">
                      <div>Overdue</div>
                      <div>Not yet due</div>
                    </div>

                    <div className="p-2 d-flex flex-column text-end ms-4 align-items-end align-items-sm-end  text-end">
                      <div>
                        {currencySymbol}{" "}
                        {parseFloat(totalOutstanding).toFixed(2)}
                      </div>
                      <div>
                        {currencySymbol} {parseFloat(totalNotYetDue).toFixed(2)}
                      </div>
                    </div>
                  </div>

                  {/* Outstanding Balance */}
                  <div
                    className="p-1 d-flex flex-row w-100 justify-content-between py-1"
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      backgroundColor: "rgba(202, 200, 200, 0.5)",
                    }}
                  >
                    <div>Outstanding Balance ({selectedCountry.label2})</div>
                    <div className="fw-bold">
                      {currencySymbol} {parseFloat(totalOutstanding).toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>

              {/* Table Section */}
              {/* <div className="table-responsive">
                <div style={{ overflowX: "auto" }}>
                  <table className="table px-2">
                    <thead>
                      <tr>
                        <th scope="col">Invoice #</th>
                        <th scope="col">Invoice date</th>
                        <th scope="col">Due date</th>
                        <th scope="col">Total</th>
                        <th scope="col">Paid</th>
                        <th scope="col">Due</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientinvoiceslist.length > 0 ? (
                        clientinvoiceslist.map((client, index) => (
                          <tr key={index}>
                            <th scope="row">
                              {" "}
                              <span style={{ color: "rgb(21, 91, 255)" }}>
                                Invoice {client.id}
                              </span>
                            </th>
                            <td>{formatDate(client.invoice_date)}</td>
                            <td>
                              {formatDate(client.updated_at)}

                              <br />
                              <span
                                style={{ color: "red", fontWeight: "bold" }}
                              >
                                Overdue
                              </span>
                            </td>
                            <td>
                              ${parseFloat(client.total_price).toFixed(2)}
                            </td>
                            <td>
                              ${parseFloat(client.paid_amount).toFixed(2)}
                            </td>
                            <td>
                              $
                              {parseFloat(
                                client.total_price - client.paid_amount
                              ).toFixed(2)}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            No invoices found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div> */}
              <ClientsTable client={clientinvoiceslist} />
              {/* Final Amount Section */}
              <div className="d-flex flex-row mb-3 justify-content-end px-2">
                <div
                  className="p-1 d-flex flex-row col-12 col-md-6 justify-content-between py-1"
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    backgroundColor: "rgba(202, 200, 200, 0.5)",
                    textAlign: "center",
                  }}
                >
                  <div>Outstanding Balance ({selectedCountry.label2})</div>
                  <div className="fw-bold">
                    {currencySymbol} {parseFloat(totalOutstanding).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row mb-3 justify-content-end p-2 ">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSendStatement}
            >
              SEND STATEMENT
            </button>

            <PDFDownloadLink
              document={
                <StatementPDF
                  clientdetails={clientdetails}
                  clientinvoiceslist={clientinvoiceslist}
                  totalOutstanding={totalOutstanding}
                  notYetDue={totalNotYetDue}
                  currencySymbol={currencySymbol}
                  selectedCountry={selectedCountry}
                />
              }
              fileName="statement.pdf"
            >
              <button type="button" className="btn btn-info mx-1">
                DOWNLOAD PDF
              </button>
            </PDFDownloadLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateStatementModal;
