import API from "./baseApi";

const getAllInvoices = async (data) => {
  try {
    const response = await API.post(`/invoice/getAllInvoices`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) {  
      throw new Error("Failed to get all invoices");
    }
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getInvoiceData = async (data) => {
  try {
    const response = await API.post(`/invoice/getInvoiceData`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) {
      throw new Error("Failed to get Invoice Data!");
    }
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const deleteInvoiceById = async (data) => {
  try {
    const response = await API.post(`/invoice/deleteInvoice`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) {
      throw new Error("Failed to delete Invoice!");
    }
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const updateInvoice = async (data) => {
  try {
    const response = await API.post("/invoice/updateInvoice", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.status === 200) {
      throw new Error("Failed to update collection");
    }
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const sendInvoice = async (data) => {
  try {
    const response = await API.post("/invoice/send-invoice", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) {
      throw new Error("Failed to send invoice");
    }
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}; 

const downloadInvoice = async (data, invoiceId = "") => {
  try {
    const response = await API.post("/invoice/downloadInvoice", data, {
      headers: {
        "Content-Type": "application/json",
      },
      responseType: "blob", // Important for handling file downloads
    });
    if (response.status !== 200) {
      throw new Error("Failed to download invoice");
    }

    // Create a Blob URL and trigger the download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", invoiceId + "-invoice.pdf"); // Default file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // return true;
  } catch (error) {
    throw new Error(error.message);
  }
};
const updateInvoiceQuickbookLink = async (data) => {
  try {
    const response = await API.post("/invoice/quickbook-link", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.status === 200) {
      throw new Error("Failed to update collection");
    }
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getActiveInvoices = async (data) => {
  try {
    const response = await API.post("/invoice/getActiveInvoices", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) {
      throw new Error("Failed to get active invoices");
    }
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const changePaidStatus = async (data) => {
  try {
    const response = await API.post("/invoice/changePaidStatus", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) {
      throw new Error("Failed to get active invoices");
    }
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


const getClientInvoices = async ({user_id}) => {
  try {
    const response = await API.post("/invoice/getClientInvoices", {user_id});
    if (response.status !== 200) {
      throw new Error("Failed to get photographers");
    }
    return response.data;
  } catch (error) {
    return error?.response?.data?.error;
  }
};
const getClientStatemenet = async ({user_id}) => {
  try {
    const response = await API.post("/invoice/getClientStatemenet", {user_id});
    if (response.status !== 200) {
      throw new Error("Failed to get photographers");
    }
    return response.data;
  } catch (error) {
    return error?.response?.data?.error;
  }
};

export {
  getAllInvoices,
  deleteInvoiceById,
  getInvoiceData,
  updateInvoice,
  sendInvoice,
  getActiveInvoices,
  updateInvoiceQuickbookLink,
  changePaidStatus,
  downloadInvoice,
  getClientInvoices,
  getClientStatemenet
};
