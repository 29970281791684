import React from "react";
import { Link, useLocation } from "react-router-dom";
import AddOrEditClientForm from "./AddOrEditClientForm";

const ManageClients = () => {
  const location = useLocation();

  return (
    <div className="app-content content">
      <div className="content-overlay"></div>
      <div className="content-wrapper">
        <div className="content-header row mt-2">
          <div className="content-header-left col-md-6 col-7 mb-2">
            <h3 className="content-header-title mb-0">{`${
              location.pathname === "/add-client" ? "Add Client" : "Edit Client"
            }`}</h3>
            <div className="row breadcrumbs-top">
              <div className="breadcrumb-wrapper col-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item">{`${
                    location.pathname === "/add-client"
                      ? "Add Client"
                      : "Edit Client"
                  }`}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="content-body"></div>
        <AddOrEditClientForm location={location}/>
      </div>
    </div>
  );
};

export default ManageClients;
