import { useEffect, useState } from "react";
import React from "react";
import { FaUpload, FaPaperPlane } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useAuth } from "../../context/authContext";
import DeleteModal from "../../components/DeleteModal";
import { toast } from "react-toastify";
//import TableCustom from "../components/TableInvoice";
import ClientInvoice from "../../components/manageClients/TableInvoiceClient";
import { verifyToken } from "../../api/authApis";
import LoadingOverlay from "../../components/Loader";
import EditInvoiceModal from "../../components/EditInvoice";
import ReTooltip from "../../components/Tooltip";
import UploadInvoiceModal from "../../components/UploadInvoiceModal";
import ConfirmModal from "../../components/ConfirmModal";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getAllInvoices,
  changePaidStatus,
  deleteInvoiceById,
  downloadInvoice,
  sendInvoice,
  updateInvoiceQuickbookLink,
  getClientInvoices,
} from "../../api/invoiceApis";
import CreateStatementModal from "./CreateStatementModal";
import { getClient } from "../../api/clientApis";
import useCountrySelect from "../../hooks/useCountrySelect";

const InvoiceClient = () => {
  const { authData, setAuthUser } = useAuth();
  const { user } = authData;

  const { id } = useParams();

  const roleId = user.role_id;
  const subdomainId = user.subdomain_id;
  const accesstoken = authData.token;
  const [invoiceList, setInvoiceList] = useState([]);
  const [invoiceId, setInvoiceId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [itemsLoading, setItemsLoading] = useState(true);
  const [isEditMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false); // New state for upload modal
  const [newInvoiceId, setNewInvoiceId] = useState(null);
  const [quickbookLink, setQuickbookLink] = useState("");
  const [showPaidModal, setShowPaidModal] = useState(false);
  const [showStatmentModal, setShowStatmentModal] = useState(false);
  const [clientdetails, setClientDetails] = useState({});

  const navigate = useNavigate();

  const { countryOptions } = useCountrySelect();
  const userCountry = roleId === 5 ? user?.country : user.adminCountry; // Assume user.country exists
  const countryData = countryOptions.find((c) => c.value === userCountry);
  const currencySymbol = countryData ? countryData.currencySymbol : "$";
  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    resetData();
  };

  const resetData = async () => {
    setInvoiceId(null);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const data = React.useMemo(() => {
    return invoiceList.map((invoice) => {
      const updatedAT = moment(invoice.updated_at).format("DD/MM/YYYY");
      return {
        ...invoice,
        updatedAT,
      };
    });
  }, [invoiceList]);

  const columns = [
    {
      Header: "Invoice Id",
      accessor: (row) => {
        return roleId !== 3 ? (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleView(row.id);
            }}
          >
            {row.invoice_id}
          </a>
        ) : (
          row.invoice_id
        );
      },
    },
    {
      Header: "Date",
      accessor: "updatedAT",
      headerStyle: { width: "200px" },
    },

    {
      Header: "Collection Id",
      accessor: "collection_id",
    },
    {
      Header: "Address",
      accessor: "user_address",
    },
    {
      Header: "Amount",
      accessor: (row) => `${currencySymbol} ${row.total_price}`,
    },
    {
      Header: "Status",
      accessor: (row) => {
        const paidStatus = row.paid_status;
        const sendInvoice = row.send_invoice;

        const paidBadge = paidStatus ? (
          <p className="badge btn-success">Paid</p>
        ) : (
          <p
            className="badge"
            style={{ backgroundColor: "rgb(255, 116, 140)" }}
          >
            Pending
          </p>
        );

        const invoiceBadge = sendInvoice && !paidStatus ? (
          <p
            className="badge btn-primary"
            style={{ cursor: "pointer" }}
            onClick={() => handleSendInvoice(row.id, true)}
          >
            Send Reminder
          </p>
        ) : null;

        return (
          <div style={{ display: "flex", gap: "5px" }}>
            {paidBadge}
            {invoiceBadge}
          </div>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => {
        const { original } = row;
        const {
          id,
          paid_status,
          send_invoice,
          invoice_link,
          quickbooks_invoice_id,
          invoice_id,
        } = original;
        const isPaid = paid_status;
        const isSent = send_invoice;
        const isQuickBookInvoiceAdded =
          quickbooks_invoice_id && quickbooks_invoice_id !== null
            ? true
            : false;

        const isDisabled =
          roleId === 3 &&
          new Date(`${original.booking_date}T${original.booking_time}`) -
            new Date() <
            1000 * 60 * 60 * 3;
        const disabledStyle = { pointerEvents: "none", opacity: 0.6 };

        return (
          <>
            {roleId !== 3 ? (
              <div className="btnsrow">
                <ReTooltip title="Edit Invoice" placement="top">
                  <button
                    type="button"
                    className="btn btn-icon btn-outline-primary mr-1 mb-1"
                    style={{
                      padding: "0.5rem",
                      ...(isSent ? disabledStyle : {}),
                    }}
                    onClick={() => handleEdit(id)}
                    disabled={isSent}
                  >
                    <i className="feather white icon-edit"></i>
                  </button>
                </ReTooltip>
                <ReTooltip title="Delete Invoice" placement="top">
                  <button
                    type="button"
                    className="btn btn-icon btn-outline-danger mr-1 mb-1"
                    style={{ padding: "0.5rem" }}
                    onClick={() => handleDelete(id)}
                    disabled={isDisabled}
                  >
                    <i className="feather white icon-trash"></i>
                  </button>
                </ReTooltip>
                {isQuickBookInvoiceAdded && (
                  <ReTooltip title="Upload Invoice" placement="top">
                    <button
                      type="button"
                      className="btn btn-icon btn-outline-primary mr-1 mb-1"
                      style={{ padding: "0.5rem" }}
                      onClick={() => handleUpload(id, invoice_link)}
                    >
                      <FaUpload fill="white" />
                    </button>
                  </ReTooltip>
                )}

                <ReTooltip title="Mark as Paid" placement="top">
                  <button
                    type="button"
                    className="btn btn-icon btn-outline-primary mr-1 mb-1 text-white"
                    style={{
                      padding: "0.5rem",
                      ...(isPaid ? disabledStyle : {}),
                    }}
                    onClick={() => handlePaid(id)}
                    disabled={isPaid}
                  >
                    Paid
                  </button>
                </ReTooltip>
                <ReTooltip title="Send Invoice" placement="top">
                  <button
                    type="button"
                    className="btn btn-icon btn-outline-primary mr-1 mb-1"
                    style={{
                      padding: "0.5rem",
                      ...(isSent ? disabledStyle : {}),
                    }}
                    onClick={() => handleSendInvoice(id, false)}
                    disabled={isSent}
                  >
                    <FaPaperPlane fill="white" />
                  </button>
                </ReTooltip>
                <ReTooltip title="Download Invoice" placement="top">
                  <button
                    type="button"
                    className="btn btn-icon btn-outline-primary mr-1 mb-1"
                    style={{ padding: "0.5rem" }}
                    onClick={() => {
                      handleDownloadInvoice(id, row.original.invoice_id);
                    }}
                    disabled={isDisabled}
                  >
                    <i class="fa fa-download white"></i>
                  </button>
                </ReTooltip>
              </div>
            ) : (
              <>
                {row.original.send_invoice ? (
                  <div className="btnsrow">
                    <ReTooltip title="View" placement="top">
                      <button
                        type="button"
                        className="btn btn-icon btn-outline-primary mr-1 mb-1"
                        style={{ padding: "0.5rem" }}
                        onClick={() => handleView(id)}
                        disabled={isDisabled}
                      >
                        <i className="fa icon-eye white"></i>
                      </button>
                    </ReTooltip>
                    <ReTooltip title="Download Invoice" placement="top">
                      <button
                        type="button"
                        className="btn btn-icon btn-outline-primary mr-1 mb-1"
                        style={{ padding: "0.5rem" }}
                        onClick={() => {
                          handleDownloadInvoice(id, row.original.invoice_id);
                        }}
                        disabled={isDisabled}
                      >
                        <i class="fa fa-download white"></i>
                      </button>
                    </ReTooltip>
                  </div>
                ) : (
                  <div className="btnsrow">None</div>
                )}
              </>
            )}
          </>
        );
      },
    },
  ];
  // Check if role ID is not 3(client) to decide whether to include "Client" column
  if (roleId !== 3) {
    columns.splice(3, 0, {
      Header: "Client",
      accessor: "user_name",
    });
  }

  const handleSendInvoice = async (id, reminder) => {
    try {
      setLoading(true);
      const response = await sendInvoice({
        rowId: id,
        currency: currencySymbol,
        reminder: reminder,
      });
      if (response.success) {
        toast.success("Invoice sent successfully!");
        getInvoiceList();
      } else {
        toast.error("Failed to send invoice!");
      }
    } catch (error) {
      console.error("Error sending invoice:", error);
      toast.error("Failed to send invoice!");
    }
    setLoading(false);
  };

  const handleDownloadInvoice = async (id, invoiceId) => {
    try {
      setLoading(true);
      await downloadInvoice({ rowId: id, currency: currencySymbol }, invoiceId);
    } catch (error) {
      console.error("Error download invoice:", error);
      toast.error("Failed to download invoice!");
    }
    setLoading(false);
  };

  const getInvoiceList = async () => {
    setItemsLoading(true);
    try {
      // const formData = new FormData();
      // formData.append("role_id", roleId);
      // formData.append("subdomain_id", subdomainId);
      // formData.append("user_id", user.id);
      const response = await getClientInvoices({ user_id: id });
      setInvoiceList(response.data);
    } catch (error) {
      console.error("Error fetching invoice list:", error);
    }
    setItemsLoading(false);
  };

  const deleteInvoice = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("id", invoiceId);
      const response = await deleteInvoiceById(formData);
      if (response.status === 200) {
        toast.success("Invoice deleted successfully!");
      }
      setShowDeleteModal(false);
      resetData();
      getInvoiceList();
    } catch (error) {
      console.error("Error deleting invoice:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const getClientData = async () => {
      console.log("skjafdhshfuidshfiudshi");

      try {
        const response = await getClient({ id: id });
        console.log("hi", response);
        if (response && response.data) {
          setClientDetails(response.data);
        } else {
          console.error("Failed to fetch client data");
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };
    getClientData();
  }, [id]);

  useEffect(() => {
    getInvoiceList();
  }, []);

  const handleEdit = (id) => {
    setEditMode(true);
    setInvoiceId(id);
    setModalIsOpen(true);
    setLoading(true);
  };

  const handleDelete = (id) => {
    setInvoiceId(id);
    setShowDeleteModal(true);
  };

  const handleUpload = (id, invoiceLink) => {
    setInvoiceId(id);
    setQuickbookLink(invoiceLink);
    setShowUploadModal(true);
  };

  const closeUploadModal = () => {
    setShowUploadModal(false);
    setQuickbookLink("");
    resetData();
  };

  const handlePaid = (id) => {
    setInvoiceId(id);
    setShowPaidModal(true);
  };

  const handlePaidClose = () => {
    setInvoiceId(null);
    setShowPaidModal(false);
  };

  const confirmPaidStatus = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("id", invoiceId);
      const response = await changePaidStatus(formData);
      if (response.status === 200) {
        toast.success("Invoice status updated successfully!");
      }
      setShowDeleteModal(false);
      resetData();
      getInvoiceList();
      setShowPaidModal(false);
    } catch (error) {
      console.error("Error updating invoice status:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (accesstoken !== undefined) {
        let resp = await verifyToken(accesstoken, subdomainId);
        if (!resp.success) {
          toast.error("Session expired, please login again.");
          window.location.href = "/login";
        } else {
          setAuthUser(resp.user);
        }
      }
    };

    fetchData();
  }, [accesstoken]);

  const handleLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (modalIsOpen) {
      setLoading(true);
    }
  }, [modalIsOpen]);

  const handleQbLinkChange = (value) => {
    setQuickbookLink(value);
  };

  const handleLinkUpload = async () => {
    setLoading(true);
    const response = await updateInvoiceQuickbookLink({
      invoiceId,
      invoiceLink: quickbookLink,
    });
    if (response.success) {
      toast.success("Link saved successfully");
      resetData();
      setQuickbookLink("");
      setShowUploadModal(false);
      getInvoiceList();
    } else {
      toast.error(response.message);
    }
    setLoading(false);
  };
  const handleView = (id) => {
    setEditMode(true);
    setInvoiceId(id);
    setModalIsOpen(true);
    setLoading(true);
  };

  return (
    <>
      <LoadingOverlay loading={loading} />

      {invoiceList.length > 0 ? (
        <ClientInvoice
          data={data}
          columns={columns}
          setShowStatmentModal={setShowStatmentModal}
        />
      ) : (
        <>
          <div className="app-content content">
            <div className="content-overlay"></div>
            <div className="content-wrapper">
              <div className="col-12 d-flex justify-content-center overflow-hidden">
                {itemsLoading ? (
                  <div className="spinner-border primary" role="status">
                    <span className="sr-only"></span>
                  </div>
                ) : (
                  <div className="col-12 text-center">
                    <p>No Invoices found.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <DeleteModal
        isOpen={showDeleteModal}
        onClose={handleDeleteModalClose}
        onConfirm={deleteInvoice}
        message="Are you sure you want to delete this Invoice?"
      />
      <EditInvoiceModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        setNewInvoiceId={setNewInvoiceId}
        newInvoiceId={newInvoiceId}
        invoiceId={invoiceId}
        handleLoading={handleLoading}
        isEdit={isEditMode}
        collectionId={null}
        refreshInvoiceList={getInvoiceList}
      />
      <ConfirmModal
        isOpen={showPaidModal}
        onClose={handlePaidClose}
        onConfirm={confirmPaidStatus}
        message="Do you wish to change the status to paid?"
      />
      <UploadInvoiceModal
        isOpen={showUploadModal}
        onClose={() => closeUploadModal()}
        quickbookLink={quickbookLink}
        handleConfirm={handleLinkUpload}
        handleQbLinkChange={handleQbLinkChange}
      />

      <CreateStatementModal
        isOpen={showStatmentModal}
        onClose={() => setShowStatmentModal(false)}
        clientdetails={clientdetails}
      />
    </>
  );
};

export default InvoiceClient;
